import { Children, renderChildren } from '@sixfold/typed-render-props';
import React from 'react';
import { Mutation } from 'react-apollo';

import { sortToGraphql } from './integrations_container';
import {
  DeleteTelematicsIntegrationMutation,
  DeleteTelematicsIntegrationMutationVariables,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { useIntegrationListSort } from '../components/integration_list';
import { deleteTelematicsIntegrationMutation, telematicsIntegrationsListQuery } from '../graphql';

class DeleteIntegrationMutationContainer extends Mutation<
  DeleteTelematicsIntegrationMutation,
  DeleteTelematicsIntegrationMutationVariables
> {}

interface Props {
  companyId: string;
  children: Children<{
    deleteTelematicsIntegration: (telematicsIntegrationId: string) => Promise<void>;
  }>;
}

export const DeleteIntegrationMutation: React.FC<Props> = ({ companyId, children }) => {
  const { sort } = useIntegrationListSort();

  return (
    <DeleteIntegrationMutationContainer mutation={deleteTelematicsIntegrationMutation}>
      {(deleteIntegration) =>
        renderChildren(children, {
          deleteTelematicsIntegration: async (telematicsIntegrationId: string) => {
            return await deleteIntegration({
              refetchQueries: [
                {
                  query: telematicsIntegrationsListQuery,
                  variables: {
                    companyId,
                    limit: PAGINATION_PAGE_SIZE,
                    sort: sortToGraphql(sort),
                  },
                },
              ],
              variables: {
                telematics_integration_id: telematicsIntegrationId,
              },
            });
          },
        })
      }
    </DeleteIntegrationMutationContainer>
  );
};
