import { useURLState } from '@sixfold/app-utils';
import { OptionalString } from '@sixfold/query-string';

import { interpolate } from './string';
import { CompanyProfile } from '../../company/entities';
import { getEmbedConfig } from '../data';

export const shipmentViewUrl = (companyId: string | null, tourId: string | null, mode: 'road' | 'ocean' | 'air') => {
  const config = getEmbedConfig();

  if (config === null || companyId === null || tourId === null) {
    return;
  }

  let urlTemplate;
  if (mode === 'air') {
    urlTemplate = config.air_shipment_view_url;
  } else if (mode === 'ocean') {
    urlTemplate = config.ocean_shipment_view_url;
  } else {
    urlTemplate = config.shipment_view_url;
  }

  return interpolate(urlTemplate, {
    company_id: companyId,
    tour_id: tourId,
  });
};
export const shipmentsViewUrl = (companyId: string | null) => {
  const config = getEmbedConfig();

  if (config === null || companyId === null) {
    return;
  }

  return interpolate(config.shipments_view_url, { company_id: companyId });
};

export const placeViewUrl = (tourCreatedBy: CompanyProfile | null, placeId: string | null) => {
  const config = getEmbedConfig();

  if (config === null || tourCreatedBy === null || placeId === null) {
    return;
  }

  return interpolate(config.place_view_url, { company_id: tourCreatedBy.company_id, place_id: placeId });
};

export const classicMapViewByCoordinatesUrl = ({ lat, lng }: { lat: number | null; lng: number | null }) => {
  const config = getEmbedConfig();

  if (lat === null || lng === null || config === null) {
    return;
  }

  return interpolate(config.google_maps_lat_lng_url, {
    lat,
    lng,
  });
};

export const classicMapViewByAddressUrl = ({ address }: { address: string }) => {
  const config = getEmbedConfig();

  if (config === null) {
    return;
  }

  return interpolate(config.google_maps_address_url, {
    address: encodeURIComponent(address),
  });
};

export const openSatelliteViewUrl = ({ lat, lng }: { lat: number | null; lng: number | null }) => {
  const config = getEmbedConfig();

  if (lat === null || lng === null || config === null) {
    return;
  }
  return window.open(interpolate(config.satelliteview_lat_lng_url, { lat, lng }), '_blank');
};

export const tourStopEtaHistoryUrl = ({ tourId, stopId }: { tourId: number; stopId: number }) => {
  const config = getEmbedConfig();

  if (config === null) {
    return;
  }

  const stopSpecification = {
    'ds0.tour_id': tourId.toString(),
    'ds0.stop_id': stopId.toString(),
  };

  return interpolate(config.data_studio_stop_eta_dashboard_url, {
    params: encodeURIComponent(JSON.stringify(stopSpecification)),
  });
};

export type UrlSort<Key extends string> = { key: Key; direction: 'ASC' | 'DESC' };
export const useUrlSort = <Key extends string>(defaultSort: UrlSort<Key>) => {
  const [urlSort, updateUrlSort] = useURLState({
    sort: OptionalString,
    sort_order: OptionalString,
  });

  const sort = {
    key: urlSort.sort ?? defaultSort.key,
    direction: urlSort.sort_order?.toUpperCase() ?? defaultSort.direction,
  } as UrlSort<Key>;

  const changeSort = (newSort: UrlSort<Key>) => {
    updateUrlSort({ sort: newSort.key, sort_order: newSort.direction.toLowerCase() });
  };

  return {
    sort,
    changeSort,
  };
};
