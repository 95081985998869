import { CountBadge } from '@sixfold/common-ui';
import React from 'react';
import { Query } from 'react-apollo';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';

import { TelematicsIntegrationsContainer } from './integrations_container';
import { TelematicsProviderContainer } from './provider_container';
import { TelematicsProvidersContainer } from './providers_container';
import { TelematicsCountsQuery } from '../../lib/graphql';
import { Routes } from '../../routes';
import { telematicsCountsQuery } from '../graphql';

class CountsQuery extends Query<TelematicsCountsQuery, never> {}

export const TelematicsContainer = () => {
  return (
    <Switch>
      <Route path={Routes.TelematicsProvider.routerPath} component={TelematicsProviderContainer} />
      <Route
        path={Routes.Telematics.routerPath}
        render={() => (
          <>
            <h2>Telematics</h2>
            <div className="ui pointing menu submenu">
              <CountsQuery query={telematicsCountsQuery}>
                {(result) => (
                  <>
                    <NavLink to={Routes.TelematicsProviders.generatePath({})} className="item">
                      {result.loading ? (
                        <>Providers</>
                      ) : (
                        <>
                          Providers{' '}
                          <CountBadge
                            size="xsmall"
                            kind="neutral"
                            value={result.data?.telematicsProvidersV2?.count ?? 0}
                          />
                        </>
                      )}
                    </NavLink>
                    <NavLink to={Routes.TelematicIntegrations.generatePath({})} className="item">
                      {result.loading ? (
                        <>Integrations</>
                      ) : (
                        <>
                          Integrations{' '}
                          <CountBadge
                            size="xsmall"
                            kind="neutral"
                            value={result.data?.telematicsIntegrations?.count ?? 0}
                          />
                        </>
                      )}
                    </NavLink>
                  </>
                )}
              </CountsQuery>
            </div>
            <Switch>
              <Route path={Routes.TelematicsProviders.routerPath} render={() => <TelematicsProvidersContainer />} />
              <Route
                path={Routes.TelematicIntegrations.routerPath}
                render={() => <TelematicsIntegrationsContainer />}
              />
              <Redirect to={Routes.TelematicsProviders.generatePath({})} />
            </Switch>
          </>
        )}
      />
    </Switch>
  );
};
