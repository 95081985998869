import { getDistance } from '@sixfold/geo-primitives';
import { createPropertyNotNilFilter, notNil } from '@sixfold/typed-primitives';

import { add, startOf, endOf, substract, compareAsc } from '../../lib/date';
import {
  GeoPositionMatchLevel,
  GeofenceType,
  GeofenceZoneType,
  TourEventName,
  TourQuery,
  StopEventName,
  TourVehicleAllocationType,
  TourFilteringTransportMode,
  GeocodingType,
} from '../../lib/graphql';
import {
  Event,
  Geofence,
  GeofenceCircle,
  GeofenceZone,
  Tour,
  TourStop,
  TourStopLocation,
  Stop,
  ExternalEvent,
  isTourExternalStopEvent,
  isTourExternalDeliveryEvent,
  isTourExternalEvent,
  TourDeliveryTree,
  DeliveryTree,
  GeofencePolygon,
  VisibilityService,
} from '../entities';

export const EXTENDED_TIME_START = 8;
export const EXTENDED_TIME_END = 3;

type CombinedEvent = NonNullable<TourQuery['tour']>['events'][number];

const transformCombinedEventToEvent = (event: CombinedEvent): Event => {
  if (event.__typename === 'TourEvent') {
    return {
      ...event,
      event_name: event.tour_event_name,
    };
  }

  return {
    ...event,
    event_name: event.stop_event_name,
  };
};

export const transformCombinedEventsToEvents = (combinedEvents: CombinedEvent[]): Event[] =>
  combinedEvents.map((combinedEvent) => transformCombinedEventToEvent(combinedEvent));

export const getExternalEvents = (tour: Tour): ExternalEvent[] => {
  return ([] as ExternalEvent[]).concat(tour.externalEvents, tour.externalDeliveryEvents, tour.externalStopEvents);
};

export const getStopsFromTourStops = (tourStops: TourStop[]): Stop[] => {
  return tourStops.map((stop) => ({
    stop_id: stop.stop_id,
    type: stop.type ?? 'loading',
    lat: stop?.location?.position?.lat ?? null,
    lng: stop?.location?.position?.lng ?? null,
    geofenceZones: stop.geofenceZones,
    timeslot: stop.timeslot,
  }));
};

export const getFormattedDelay = (delayInSeconds: number) => {
  const hours = Math.floor(delayInSeconds / 3600);
  const minutes = Math.floor((delayInSeconds - hours * 3600) / 60);
  const seconds = (delayInSeconds % 3600) % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }

  return `${seconds}s`;
};

export const getFormattedLocation = (location: TourStopLocation | null) => {
  if (
    location === null ||
    location.address === null ||
    (location.name === null && location.address.full_address === null)
  ) {
    return '';
  }

  const { name, address } = location;
  if (name === address.full_address) {
    return name!;
  } else if (name !== null && address.full_address !== null) {
    return `${name} (${address.full_address})`;
  } else if (name !== null) {
    return name;
  } else {
    return address.full_address!;
  }
};

export const getFormattedMatchLevel = (location: TourStopLocation | null) => {
  const locationPositionMatchLevel = location?.position?.matchLevel ?? null;
  if (locationPositionMatchLevel === null) {
    return '-';
  }

  switch (locationPositionMatchLevel) {
    case GeoPositionMatchLevel.CITY:
      return 'city';
    case GeoPositionMatchLevel.COUNTRY:
      return 'country';
    case GeoPositionMatchLevel.EXTPOSTCODE:
      return 'extended postcode';
    case GeoPositionMatchLevel.HOUSE_NUMBER:
      return 'house number';
    case GeoPositionMatchLevel.STREET:
      return 'street ';
    default:
      return 'unknown';
  }
};

export const getFormattedGeocodingType = (location: TourStopLocation | null) => {
  const platformGeocodingType = location?.position?.geocoding_type ?? null;
  if (platformGeocodingType === null) {
    return '-';
  }

  switch (platformGeocodingType) {
    case GeocodingType.AUTOMATICALLY_GEOCODED:
      return 'automatically geocoded';
    case GeocodingType.MANUAL:
      return 'manual';
    case GeocodingType.SHIPPER:
      return 'shipper';
    case GeocodingType.CARRIER:
      return 'carrier';
    default:
      return 'unknown';
  }
};

export const numberOfStatusProperties = (tour: Tour) => {
  let numberOfProperties = 16;
  if (
    tour.activeVehicleAllocation !== null &&
    tour.activeVehicleAllocation.allocationType === TourVehicleAllocationType.TOUR_ALLOCATION &&
    tour.activeVehicleAllocation.startTrackingAt !== null
  ) {
    numberOfProperties += 1;
  }
  if (tour.isTrackingDisabled === true) {
    numberOfProperties += 1;
  }

  return numberOfProperties;
};

export const getRowSpan = <T>(
  obj: T | null,
  amountOfProperties: number,
  checker: ((obj: T, key: keyof T) => boolean) | null,
  ...keys: (keyof T)[]
) => {
  if (obj == null) return amountOfProperties;

  if (checker != null) {
    return Math.max(amountOfProperties - keys.filter((key) => obj[key] == null || checker(obj, key)).length, 1);
  }
  return Math.max(amountOfProperties - keys.filter((key) => obj[key] == null).length, 1);
};

const isFinalTourEvent = (eventName: TourEventName | StopEventName): eventName is TourEventName => {
  switch (eventName) {
    case TourEventName.COMPLETE:
    case TourEventName.ABANDON:
    case TourEventName.ABORT:
    case TourEventName.FAILED:
      return true;
    default:
      return false;
  }
};

export const extractVehicleHistoryVariables = (tour: Pick<Tour, 'tour_id' | 'events'>) => {
  const { tour_id, events } = tour;

  const eventsSortedByTime = events
    .filter(createPropertyNotNilFilter('event_time'))
    .sort((a, b) => compareAsc(new Date(a.event_time), new Date(b.event_time)));

  const firstEvent = eventsSortedByTime[0];
  const lastEvent =
    eventsSortedByTime.find((event) => isFinalTourEvent(event.event_name)) ??
    eventsSortedByTime[eventsSortedByTime.length - 1];

  const todayStart = startOf(new Date(), 'hour');
  const todayEnd = endOf(new Date(), 'hour');

  // We default to the start/end of hour so that a URL change doesn't automatically
  // generate a new Date() (causing a re-fetch of vehicle history)
  const firstEventTime =
    notNil(firstEvent) && notNil(firstEvent.event_time) ? new Date(firstEvent.event_time) : todayStart;
  const lastEventTime = notNil(lastEvent) && notNil(lastEvent.event_time) ? new Date(lastEvent.event_time) : todayEnd;

  // Add some padding and make sure end is not before start (so worst case we just get the padding around "start")
  const startTime = substract(firstEventTime, {
    hours: EXTENDED_TIME_START,
  });
  const endTime = add(lastEventTime, {
    hours: EXTENDED_TIME_END,
  });

  return { tour_id, start_time: startTime, end_time: endTime };
};

export const getCircleGeofencesByType = (zones: GeofenceZone[], zoneType: GeofenceZoneType) =>
  zones
    .filter(({ zone }) => zone === zoneType)
    .map(({ geofence }) => geofence)
    .filter((geofence: Geofence): geofence is GeofenceCircle => geofence.type === GeofenceType.CIRCLE);

export const getPolygonGeofencesByType = (zones: GeofenceZone[], zoneType: GeofenceZoneType) =>
  zones
    .filter(({ zone }) => zone === zoneType)
    .map(({ geofence }) => geofence)
    .filter((geofence: Geofence): geofence is GeofencePolygon => geofence.type === GeofenceType.POLYGON);

export const calculateDistanceInMeters = ([fromLng, fromLat]: [number, number], [toLng, toLat]: [number, number]) => {
  return getDistance({ lng: fromLng, lat: fromLat }, { lng: toLng, lat: toLat });
};

export const externalEventLabel = (event: ExternalEvent) => {
  const qualifier = event.qualifier !== 'OTHER' ? event.qualifier.toLowerCase() : `"${event.externalEventQualifier}"`;

  if (isTourExternalStopEvent(event)) {
    return `stop #${event.stopId} ${qualifier}`;
  }

  if (isTourExternalDeliveryEvent(event)) {
    return `delivery #${event.deliveryId} ${qualifier}`;
  }

  if (isTourExternalEvent(event)) {
    return `tour ${qualifier}`;
  }

  return '';
};

export function convertFlatDeliveryTreeToTree(deliveryTrees: TourDeliveryTree[]): DeliveryTree[] {
  const rootTrees: DeliveryTree[] = [];
  const lookupMap = new Map<string, DeliveryTree | Partial<DeliveryTree>>();

  for (const deliveryTree of deliveryTrees) {
    const { deliveryTreeId } = deliveryTree;
    const { parentDeliveryTreeId } = deliveryTree;

    const deliveryTreeRuntype: DeliveryTree = convertEntityToDeliveryTree(deliveryTree);

    if (!lookupMap.has(deliveryTreeId)) {
      lookupMap.set(deliveryTreeId, { childDeliveries: [] });
    }

    const existingItem = lookupMap.get(deliveryTreeId);
    const updatedItem = {
      ...existingItem,
      ...deliveryTreeRuntype,
      childDeliveries: existingItem?.childDeliveries !== undefined ? [...existingItem.childDeliveries] : [],
    };
    lookupMap.set(deliveryTreeId, updatedItem);

    if (parentDeliveryTreeId === null) {
      rootTrees.push(updatedItem);
    } else {
      if (!lookupMap.has(parentDeliveryTreeId)) {
        lookupMap.set(parentDeliveryTreeId, { childDeliveries: [] });
      }

      const parent = lookupMap.get(parentDeliveryTreeId);

      if (parent?.childDeliveries !== undefined) {
        parent.childDeliveries.push({
          ...updatedItem,
          tourId: deliveryTreeRuntype.tourId,
        });
      }
    }
  }
  return rootTrees;
}
export function getStatusLabelColor(status: Tour['status']) {
  switch (status) {
    case 'unallocated':
      return 'grey';
    case 'allocated':
    case 'waiting':
      return 'teal';
    case 'working':
      return 'yellow';
    case 'completed':
      return 'green';
    case 'abandoned':
    case 'aborted':
    case 'failed':
      return 'red';
  }

  return ''; // light gray
}

export function extractFieldValueFromCustomFields(tour: Tour, fieldName: string) {
  const customField = tour.customFields.find((f) => f.fieldName === fieldName);
  if (customField === undefined) {
    return '-';
  }
  return customField.values[0].value;
}

function convertEntityToDeliveryTree(entity: TourDeliveryTree): DeliveryTree {
  return {
    tourId: entity.sourceTourId,
    platformPreviousDeliveryId: entity.platformPreviousDeliveryId !== null ? entity.platformPreviousDeliveryId : null,
    createdAt: entity.createdAt,
    updatedAt: entity.updatedAt,
    childDeliveries: [],
    deliveryTreeId: entity.deliveryTreeId,
    platformDeliveryId: entity.platformDeliveryId,
    platformId: entity.platformId,
    sequenceNumber: entity.sequenceNumber,
    customFields: entity.customFields,
    loadingStop: entity.loadingStop,
    unloadingStop: entity.unloadingStop,
    deliveryNumber: entity.deliveryNumber,
    platformSequenceNumber: entity.platformSequenceNumber,
    purchaseOrderNumber: entity.purchaseOrderNumber,
    orderNumber: entity.orderNumber,
  };
}

export function getFormattedGeocodingDirection(geocodingDirection: string | null | undefined) {
  return geocodingDirection ? geocodingDirection.toLocaleLowerCase() : '-';
}

export const ALL_TRANSPORT_MODES = Object.values(TourFilteringTransportMode);

export function mapToTransportMode(mode: string): TourFilteringTransportMode | undefined {
  switch (mode.toLowerCase()) {
    case 'ocean':
      return TourFilteringTransportMode.Ocean;
    case 'air':
      return TourFilteringTransportMode.Air;
    case 'road':
      return TourFilteringTransportMode.Road;
    default:
      return undefined;
  }
}

export function getTransportModesFromFilter(transportMode: string | undefined): TourFilteringTransportMode[] {
  if (transportMode === undefined) {
    return ALL_TRANSPORT_MODES;
  }

  return transportMode.split(',').map(mapToTransportMode).filter(notNil);
}

export function isAirTour(visibilityServices: VisibilityService[]) {
  return visibilityServices.includes(VisibilityService.VISIBILITY_AIR);
}

export function isOceanTour(visibilityServices: VisibilityService[]) {
  return visibilityServices.includes(VisibilityService.VISIBILITY_OCEAN);
}

export function getMode(visibilityServices: VisibilityService[]) {
  if (isAirTour(visibilityServices)) {
    return 'air';
  } else if (isOceanTour(visibilityServices)) {
    return 'ocean';
  } else {
    return 'road';
  }
}
