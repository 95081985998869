import { useNotifications } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { sortToGraphql } from './integrations_container';
import {
  getNodes,
  TelematicsProvidersSimpleListQuery,
  CreateTelematicsIntegrationMutation,
  CreateTelematicsIntegrationMutationVariables,
  PAGINATION_PAGE_SIZE,
} from '../../lib/graphql';
import { CreateIntegrationViewDataProps, CreateIntegrationView } from '../components/create_integration';
import { useIntegrationListSort } from '../components/integration_list';
import {
  telematicsProvidersSimpleListQuery,
  createTelematicsIntegrationMutation,
  telematicsIntegrationsListQuery,
} from '../graphql';

class LoadingContainer extends Loader<TelematicsProvidersSimpleListQuery, CreateIntegrationViewDataProps, object> {}
class CreateIntegrationContainerQuery extends Query<TelematicsProvidersSimpleListQuery> {}
class CreateIntegrationContainerCreateIntegrationMutation extends Mutation<
  CreateTelematicsIntegrationMutation,
  CreateTelematicsIntegrationMutationVariables
> {}

export const CreateIntegrationContainer = (props: RouteComponentProps<{ company_id: string }>) => {
  const { company_id } = props.match.params;
  const notify = useNotifications();
  const { sort } = useIntegrationListSort();

  return (
    <CreateIntegrationContainerQuery query={telematicsProvidersSimpleListQuery}>
      {(result) => (
        <CreateIntegrationContainerCreateIntegrationMutation
          mutation={createTelematicsIntegrationMutation}
          refetchQueries={[
            {
              query: telematicsIntegrationsListQuery,
              variables: {
                companyId: company_id,
                limit: PAGINATION_PAGE_SIZE,
                sort: sortToGraphql(sort),
              },
            },
          ]}>
          {(createIntegration) => (
            <LoadingContainer
              result={result}
              mapData={({ data }) => ({
                data: { company_id, telematicsProviders: getNodes(data.telematicsProvidersV2?.providers) },
              })}>
              {({ data }) => (
                <CreateIntegrationView
                  data={data}
                  createIntegration={async (input) => {
                    const response = await createIntegration(input);
                    notify.success({ title: 'Integration added' });
                    return response;
                  }}
                  routeProps={{ push: props.history.push, goBack: props.history.goBack }}
                />
              )}
            </LoadingContainer>
          )}
        </CreateIntegrationContainerCreateIntegrationMutation>
      )}
    </CreateIntegrationContainerQuery>
  );
};
