import { gql } from 'graphql-tag';

export const integrationInfoFragment = gql`
  fragment IntegrationInfo on TelematicsIntegration {
    telematics_integration_id
    telematics_provider_id
    company_integration_id
    company_id
    connection_data
    enabled
    diagnosticStatus
    diagnosticComment
    createdAt
    updatedAt
    isImmutable
    isDeletable
    integrationApiDebugUrl

    telematicsProvider {
      telematicsProviderId
      telematicsProviderName
      isVehicleManagementEnabled
      isDeprecated

      connectionParameters(includeInternal: true) {
        fieldName
        fieldType
        isInternal
        isOptional
        isSensitive
        fieldDisplayName
        enumFieldValues {
          value
          display_name
          is_default
        }
      }
    }

    company {
      company_id
      company_name
    }

    createdBy {
      user_id
      first_name
      last_name
    }

    updatedBy {
      user_id
      first_name
      last_name
    }

    integrationSyncHistory {
      startTimeAt
      endTimeAt
      vehicleCount
      statusCount
      temperatureCount
      distinctVehicleStatusFutureCount
      distinctVehicleTemperatureFutureCount
      distinctVehicleNullIslandCount
      vehicleAmbiguityCount
      latestStatusTimestamp
      latestTemperatureTimestamp
      errorDescription
      errorType
      resultType
    }
  }
`;

export const telematicsProvidersListQuery = gql`
  query TelematicsProvidersList(
    $limit: Int
    $cursor: String
    $isIntegrationImplemented: Boolean
    $hasIntegrationInstructionsURL: Boolean
    $isInternalUseOnly: Boolean
    $isExternalConfigurationNeeded: Boolean
    $isGlobalAllocationForbidden: Boolean
    $isVehicleManagementEnabled: Boolean
    $isStandardTelematicsPushProvider: Boolean
    $isDeprecated: Boolean
    $textSearch: String
    $sort: TelematicsProvidersSortInput
  ) {
    telematicsProvidersV2(
      isIntegrationImplemented: $isIntegrationImplemented
      hasIntegrationInstructionsURL: $hasIntegrationInstructionsURL
      isInternalUseOnly: $isInternalUseOnly
      isExternalConfigurationNeeded: $isExternalConfigurationNeeded
      isGlobalAllocationForbidden: $isGlobalAllocationForbidden
      isVehicleManagementEnabled: $isVehicleManagementEnabled
      isStandardTelematicsPushProvider: $isStandardTelematicsPushProvider
      isDeprecated: $isDeprecated
      textSearch: $textSearch
      sort: $sort
    ) {
      count
      providers(first: $limit, after: $cursor) {
        edges {
          node {
            telematicsProviderId
            telematicsProviderName
            totalTrucksCount
            activeTrucksCount
            integrationInstructionsURL
            isInternalUseOnly
            isIntegrationImplemented
            isExternalConfigurationNeeded
            isGlobalAllocationForbidden
            isVehicleManagementEnabled
            isStandardTelematicsPushProvider
            isDeprecated

            telematicsIntegrations {
              count
            }

            enabledIntegrations: telematicsIntegrations(isEnabled: true) {
              count
            }
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const telematicsProvidersSimpleListQuery = gql`
  query TelematicsProvidersSimpleList {
    telematicsProvidersV2 {
      providers(first: 1500) {
        edges {
          node {
            telematicsProviderId
            telematicsProviderName
            isIntegrationImplemented
            isDeprecated

            connectionParameters(includeInternal: true) {
              fieldName
              fieldType
              isInternal
              isOptional
              isSensitive
              fieldDisplayName
              enumFieldValues {
                value
                display_name
                is_default
              }
            }
          }
        }
      }
    }
  }
`;

export const telematicsProviderQuery = gql`
  ${integrationInfoFragment}

  query TelematicsProvider(
    $telematics_provider_id: String!
    $cursor: String
    $first: Int
    $sort: TelematicsIntegrationsSortInput
  ) {
    telematicsProvider(telematics_provider_id: $telematics_provider_id) {
      telematicsProviderId
      telematicsProviderName
      integrationInstructionsURL
      isInternalUseOnly
      isIntegrationImplemented
      isExternalConfigurationNeeded
      isVehicleManagementEnabled
      isStandardTelematicsPushProvider
      isGlobalAllocationForbidden
      isDeprecated
      contact_info {
        email_address
        comment
        updated_at
      }

      connectionParameters(includeInternal: true) {
        fieldName
        fieldType
        isInternal
        isOptional
        isSensitive
        fieldDisplayName
        enumFieldValues {
          value
          display_name
          is_default
        }
      }

      telematicsIntegrations(sort: $sort) {
        integrations(after: $cursor, first: $first) {
          edges {
            node {
              ...IntegrationInfo
            }
          }

          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

export const telematicsIntegrationQuery = gql`
  ${integrationInfoFragment}

  query TelematicsIntegration($telematics_integration_id: String!) {
    telematicsIntegration(telematics_integration_id: $telematics_integration_id) {
      ...IntegrationInfo
    }
  }
`;

export const updateTelematicsProviderMutation = gql`
  mutation UpdateTelematicsProvider($input: UpdateTelematicsProviderInput!) {
    updateTelematicsProvider(input: $input) {
      contact_info {
        email_address
        comment
        updated_at
      }
    }
  }
`;

export const updateTelematicsIntegrationMutation = gql`
  ${integrationInfoFragment}

  mutation UpdateTelematicsIntegration($input: UpdateTelematicsIntegrationInput!) {
    updateTelematicsIntegration(input: $input) {
      company {
        company_id
        company_name
      }
      ...IntegrationInfo
    }
  }
`;

export const createTelematicsIntegrationMutation = gql`
  ${integrationInfoFragment}

  mutation CreateTelematicsIntegration($input: CreateTelematicsIntegrationInput!) {
    createTelematicsIntegration(input: $input) {
      company {
        company_id
        company_name
      }
      ...IntegrationInfo
    }
  }
`;

export const deleteTelematicsIntegrationMutation = gql`
  mutation DeleteTelematicsIntegration($telematics_integration_id: String!) {
    deleteTelematicsIntegration(telematics_integration_id: $telematics_integration_id)
  }
`;

export const deleteTelematicsIntegrationSyncStateMutation = gql`
  mutation DeleteTelematicsIntegrationSyncState($telematics_integration_id: String!) {
    deleteTelematicsIntegrationSyncState(telematics_integration_id: $telematics_integration_id)
  }
`;

export const telematicsIntegrationsListQuery = gql`
  ${integrationInfoFragment}

  query TelematicsIntegrationsList(
    $limit: Int
    $cursor: String
    $textSearch: String
    $companyId: String
    $sort: TelematicsIntegrationsSortInput
  ) {
    telematicsIntegrations(textSearch: $textSearch, companyId: $companyId, sort: $sort) {
      count
      integrations(first: $limit, after: $cursor) {
        edges {
          node {
            company_id
            ...IntegrationInfo
          }
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const telematicsCountsQuery = gql`
  query TelematicsCountsQuery {
    telematicsProvidersV2 {
      count
    }
    telematicsIntegrations {
      count
    }
  }
`;

export const vehicleTrackerByTelematicsIntegrationAndVehicleDetailsQuery = gql`
  query VehicleTrackerByTelematicsIntegrationAndVehicleDetails(
    $telematicsIntegrationId: String!
    $remoteId: String
    $licensePlate: String
  ) {
    vehicleTrackerByTelematicsIntegrationAndVehicleDetails(
      telematicsIntegrationId: $telematicsIntegrationId
      remoteId: $remoteId
      licensePlate: $licensePlate
    ) {
      vehicleTrackerId
    }
  }
`;
