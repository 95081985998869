import { Box, Checkbox, Link, InfiniteTable } from '@sixfold/common-ui';
import { Form as FormFactory } from '@sixfold/form-component';
import {
  BackofficeScope,
  BackofficeScopeTooltips,
  hasOneOfScopes,
  ReadOnlyRoleEquivalentScopes,
} from '@sixfold/session-interface';
import { Table } from '@sixfold/table-component';
import { notNil } from '@sixfold/typed-primitives';
import { History } from 'history';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import { Button } from '../../components/button';
import { ConfirmButton } from '../../components/confirm_button';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  AddCredentialsToUserInput,
  BackofficeRole,
  CredentialType,
  RemoveCredentialsFromUserInput,
  UserQuery,
} from '../../lib/graphql';
import { Omit } from '../../lib/types';
import { generateRandomHexString } from '../../lib/util/string';
import { Routes } from '../../routes';
import { UserCredential } from '../entities';

type CompanyType = NonNullable<Required<UserQuery['user']>>['companies'][number];
class CompaniesTable extends Table<CompanyType> {}

export type UserFormData = {
  first_name?: string;
  last_name?: string;
  email: string;
  locale?: string;
  backoffice_role?: string;
  backoffice_scopes: string[] | null;
  auth_service?: string;
};

interface UserMutationInput extends Omit<UserFormData, 'backoffice_role'> {
  backoffice_role: BackofficeRole | null;
}

export interface UserViewDataProps {
  data?: {
    user: UserQuery['user'] | null | undefined;
  };
}

interface RouteProps {
  routeProps: {
    push: History['push'];
    goBack: History['goBack'];
  };
}

type Props = UserViewDataProps &
  RouteProps & {
    createUser?: (authService: string, input: UserMutationInput) => Promise<void>;
    updateUser?: (userId: string, input: UserMutationInput) => Promise<void>;
    deleteUser?: (userId: string) => Promise<void>;
    removeCredentialsFromUser?: (input: RemoveCredentialsFromUserInput) => Promise<void>;
    addCredentialsToUser?: (input: AddCredentialsToUserInput) => Promise<void>;
    isNewUser: boolean;
    scopes: string[];
  };

type UserScopes = {
  isAuthorized: boolean;
  scope: string;
  id: string;
}[];

class Form extends FormFactory<UserFormData> {}
class CredentialsTable extends Table<UserCredential> {}

export const UserView: React.FunctionComponent<Props> = ({
  routeProps,
  createUser,
  updateUser,
  deleteUser,
  removeCredentialsFromUser,
  addCredentialsToUser,
  data,
  isNewUser,
  scopes,
}) => {
  const user = data?.user ?? null;

  const isAdmin = useIsAdmin();
  const hasUserEditCredentialsPermission = useHasScopes([BackofficeScope.userWriteCredentials]);
  const hasUserReadPermission = useHasScopes([BackofficeScope.userReadUser]);

  const isDisabled = !isAdmin;
  const userCanEditCredentials = isAdmin || hasUserEditCredentialsPermission;

  const [userScopes, setUserScopes] = useState(
    scopes.map((scope) => ({
      scope,
      isAuthorized: hasOneOfScopes(user?.backoffice_scopes ?? [], [scope]),
      id: scope.toString(),
    })),
  );
  const [userRole, setUserRole] = useState(
    isNewUser ? '' : user !== null && notNil(user.backoffice_role) ? user.backoffice_role : '',
  );

  if (!isNewUser && user === null) {
    return <div>This user does not exist</div>;
  }

  return (
    <React.Fragment>
      {user !== null && (
        <h3>
          <span style={{ marginRight: 20 }}>{`#${user.user_id} ${user.first_name} ${user.last_name}`.trim()}</span>
        </h3>
      )}
      <Form
        validateFields={{ first_name: { exist: 'Missing first name' }, email: { exist: 'Missing email' } }}
        initialValues={{
          first_name: user?.first_name ?? '',
          last_name: user?.last_name ?? '',
          email: user?.email ?? '',
          locale: user?.locale ?? '',
          backoffice_role: userRole,
          backoffice_scopes: isNewUser ? null : (user?.backoffice_scopes ?? null),
        }}
        onSubmit={async (form) => {
          if (isNewUser && createUser !== undefined) {
            await createUser(form.auth_service ?? 'google', {
              first_name: form.first_name?.trim(),
              last_name: form.last_name?.trim(),
              email: form.email.trim(),
              locale: form.locale?.trim(),
              backoffice_role: convertRole(userRole),
              backoffice_scopes: null,
            });
          }

          if (user !== null && updateUser !== undefined) {
            await updateUser(user.user_id, {
              first_name: form.first_name?.trim(),
              last_name: form.last_name?.trim(),
              email: form.email.trim(),
              locale: form.locale?.trim(),
              backoffice_role: convertRole(userRole),
              backoffice_scopes: userScopes.filter((s) => s.isAuthorized).map((s) => s.scope),
            });
          }

          return routeProps.push('/users');
        }}>
        {({ form, errors, onChange, onSubmit, isSubmittingForm }) => {
          return (
            <form className="ui form" onSubmit={onSubmit}>
              <div className="two fields">
                <div className="field">
                  <label>{errors.first_name ?? 'First Name'}</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={form.first_name}
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </div>
                <div className="field">
                  <label>Last name</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={form.last_name}
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="three fields">
                <div className="field">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </div>
                <div className="field">
                  <label>Locale</label>
                  <input
                    type="text"
                    name="locale"
                    placeholder="Locale"
                    value={form.locale}
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </div>
                <div className="field">
                  <label>Backoffice role</label>
                  <select
                    className="ui fluid search dropdown"
                    name="backoffice_role"
                    value={userRole}
                    onChange={(e) => {
                      selectRoleRelevantScopes(e.target.value, userScopes, setUserScopes);
                      setUserRole(e.target.value);
                      onChange(e);
                    }}
                    disabled={isDisabled}>
                    <option value="">None</option>
                    <option value={BackofficeRole.readonly}>Read Only</option>
                    <option value={BackofficeRole.admin}>Admin</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <div className="small secondary scope__header">
                  <h3 className="table__header">Backoffice user permissions</h3>
                  <Popup
                    trigger={<Icon name="question circle outline" size="large" className="permission-tip" />}
                    position="top right"
                    hoverable
                    wide="very">
                    <p>
                      Backoffice permissions give users access to specific parts of backoffice. Useful when a user
                      should be able to view/edit some parts without having to give them the all-encompassing
                      &apos;admin&apos; role.
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>{'NB! '}</span>
                      Currently, users with &apos;admin&apos; role have access to everything in backoffice regardless of
                      which permissions they have and users without any roles have limited access regardless of their
                      permissions. So, in practice, currently the permissions are mainly useful for &apos;readonly&apos;
                      role users who should have access to some additional parts of backoffice the role doesn&apos;t
                      grant them.
                    </p>
                  </Popup>
                </div>
                <div
                  style={{
                    marginBottom: 10,
                  }}>
                  <span>Presets: </span>
                  <Button
                    type="button"
                    size="tiny"
                    title={'Read Only: Access to backoffice information in read-only mode'}
                    onClick={() => setPermissionsAndRole(1, userScopes, setUserScopes, setUserRole)}>
                    Level 1
                  </Button>
                  <Button
                    type="button"
                    size="tiny"
                    title={'Read+Write: Access to backoffice information with the ability to edit it'}
                    onClick={() => setPermissionsAndRole(2, userScopes, setUserScopes, setUserRole)}>
                    Level 2
                  </Button>
                  <Button
                    type="button"
                    size="tiny"
                    title={
                      'Read+Write including credentials: Access to backoffice information including FMS credentials and adding/removing API keys and being able to edit them'
                    }
                    onClick={() => setPermissionsAndRole(3, userScopes, setUserScopes, setUserRole)}>
                    Level 3
                  </Button>
                </div>
                <Box
                  style={{
                    height: 250,
                  }}>
                  <InfiniteTable
                    columns={[
                      {
                        id: 'scope',
                        title: 'Scope',
                        width: '40%',
                        cellContent: (data) => {
                          const tooltipText =
                            BackofficeScopeTooltips[data.row.scope as keyof typeof BackofficeScopeTooltips];

                          return (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const newScopes = [...userScopes];
                                newScopes[data.rowIndex].isAuthorized = !newScopes[data.rowIndex].isAuthorized;
                                setUserScopes(newScopes);
                              }}>
                              <span>{data.row.scope}</span>{' '}
                              {tooltipText && (
                                <Popup
                                  trigger={<Icon name="question circle outline" size="large" />}
                                  position="top right"
                                  hoverable
                                  wide="very">
                                  <p>{tooltipText}</p>
                                </Popup>
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        id: 'isAuthorized',
                        title: 'Authorized',
                        width: 'auto',
                        cellContent: (data) => {
                          return (
                            <Checkbox
                              hideLabel
                              label={data.row.scope}
                              checked={data.value}
                              onChange={(e) => {
                                const newScopes = [...userScopes];
                                newScopes[data.rowIndex].isAuthorized = e.checked;
                                setUserScopes(newScopes);
                              }}
                            />
                          );
                        },
                      },
                    ]}
                    data={userScopes}
                  />
                </Box>
              </div>
              <div className="two fields">
                {isNewUser && (
                  <div className="field">
                    <label>Auth service</label>
                    <select className="ui fluid search dropdown" name="auth_service" disabled={true}>
                      <option value="google">Google</option>
                    </select>
                  </div>
                )}
              </div>
              <Button type="submit" primary loading={isSubmittingForm} disabled={isDisabled || isSubmittingForm}>
                {createUser !== undefined ? 'Create' : 'Update'}
              </Button>
              <Button type="button" onClick={() => routeProps.goBack()}>
                Cancel
              </Button>

              {user !== null && deleteUser !== undefined && (
                <ConfirmButton
                  disabled={isDisabled}
                  initialButtonColor="red"
                  label="Delete user"
                  className="right floated"
                  onConfirm={async () => {
                    await deleteUser(user.user_id);
                    routeProps.goBack();
                  }}
                />
              )}
            </form>
          );
        }}
      </Form>
      <div className="ui divider" />

      <div>
        <h3 className="table__header">User companies</h3>
        <CompaniesTable
          className="ui very basic sortable unstackable table"
          data={user?.companies ?? []}
          tableHeaders={{
            columns: [
              {
                keyPath: 'company_id',
                value: 'Company ID',
              },
              {
                keyPath: 'company_name',
                value: 'Name',
              },
              {
                value: 'User in company',
              },
              {
                value: 'User on platforms',
              },
            ],
          }}>
          {({ row: { data: companyData, index } }) => (
            <tr key={index}>
              <td>{companyData.company_id}</td>
              <td>
                <NavLink to={Routes.Company.generatePath({ company_id: companyData.company_id })}>
                  <span>{companyData.company_name}</span>
                </NavLink>
              </td>
              <td>
                {user && (
                  <NavLink
                    to={Routes.CompanyUser.generatePath({
                      company_id: companyData.company_id,
                      user_id: user.user_id,
                    })}>
                    <span>View user in company</span>
                  </NavLink>
                )}
              </td>
              <td>
                {companyData.user &&
                  companyData.user.userOnPlatforms.map((uop) => (
                    <Link
                      className="ui label tiny"
                      key={`${uop.platform.platform_id}-${uop.platform.platform_id}`}
                      href={Routes.CompanyPlatform.generatePath({
                        company_id: companyData.company_id,
                        platform_id: uop.platform.platform_id,
                      })}>
                      {uop.platform.platform_name}:{uop.userIdOnPlatform}
                    </Link>
                  ))}
              </td>
            </tr>
          )}
        </CompaniesTable>
      </div>

      {(isAdmin || hasUserReadPermission) && user !== null ? (
        <React.Fragment>
          <div className="ui divider" />
          <div>
            <h3 className="table__header">
              <span>Credentials</span>
              {addCredentialsToUser !== undefined && (
                <Button
                  disabled={!userCanEditCredentials}
                  onClick={async () => {
                    await addCredentialsToUser({
                      userId: user.user_id,
                      type: CredentialType.API_KEY,
                      value: generateRandomHexString(40),
                    });
                  }}>
                  Add API key
                </Button>
              )}
              {addCredentialsToUser !== undefined && (
                <Button
                  disabled={!userCanEditCredentials}
                  onClick={async () => {
                    await addCredentialsToUser({
                      userId: user.user_id,
                      type: CredentialType.EXT_SHIPMENT_STATUS_API_KEY,
                      value: generateRandomHexString(40),
                    });
                  }}>
                  Add External Status API key
                </Button>
              )}
            </h3>
          </div>
          <CredentialsTable
            className="ui very basic sortable unstackable table"
            data={user.credentials ?? []}
            defaultSortBy={{ keyPath: 'user_id', value: 'ASC' }}
            onSortByChange={(sortBy) => {
              return { ...sortBy, fallback: 'user_id' };
            }}
            tableHeaders={{
              defaultClassName: getTableHeaderClassNames,
              columns: [
                { keyPath: 'type', value: 'Type' },
                { keyPath: 'value', value: 'Value' },
                { keyPath: 'addedByUserId', value: 'Added by user ID' },
                { keyPath: 'addedAt', value: 'Added at' },
                ...(removeCredentialsFromUser !== undefined ? [{ value: 'Remove credentials' }] : []),
              ],
            }}
            emptyStatePlaceholder={emptyTableState('No credentials')}>
            {({ row }) => {
              const { data: credentials } = row;

              return (
                <tr key={`${credentials.type}:${credentials.value}`}>
                  <td className="right aligned">
                    {credentials.type === 'API_KEY' && <div className="ui label blue tiny">API key</div>}
                    {credentials.type === 'EXT_SHIPMENT_STATUS_API_KEY' && (
                      <div className="ui label blue tiny">Ext API key</div>
                    )}
                  </td>
                  <td>{credentials.value ?? '-'}</td>
                  <td>
                    {credentials.addedByUserId !== undefined && (
                      <NavLink to={Routes.Users.generatePath({ user_id: credentials.addedByUserId })}>
                        #{credentials.addedByUserId}
                      </NavLink>
                    )}
                  </td>
                  <td>
                    <FormattedDate date={credentials.addedAt} />
                  </td>
                  {removeCredentialsFromUser !== undefined && (
                    <td>
                      <ConfirmButton
                        disabled={!userCanEditCredentials}
                        initialButtonColor="red"
                        onConfirm={async () => {
                          await removeCredentialsFromUser({
                            userId: user.user_id,
                            value: credentials.value ?? '',
                            type: credentials.type,
                          });
                        }}
                        label="Remove"
                      />
                    </td>
                  )}
                </tr>
              );
            }}
          </CredentialsTable>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

function selectRoleRelevantScopes(
  value: string,
  userScopes: UserScopes,
  setUserScopes: Dispatch<SetStateAction<UserScopes>>,
) {
  const unselectAll = () => {
    setUserScopes(userScopes.map((s) => ({ ...s, isAuthorized: false })));
  };
  const selectAll = () => {
    setUserScopes(userScopes.map((s) => ({ ...s, isAuthorized: true })));
  };
  const selectAllReadonlyRoleEquivalent = () => {
    setUserScopes(
      userScopes.map((s) => ({
        ...s,
        isAuthorized: ReadOnlyRoleEquivalentScopes.includes(s.scope as BackofficeScope),
      })),
    );
  };

  unselectAll();

  if (value === 'admin') {
    selectAll();
    return;
  } else if (value === 'readonly') {
    selectAllReadonlyRoleEquivalent();
    return;
  }
}

function setPermissionsAndRole(
  level: number,
  userScopes: UserScopes,
  setUserScopes: Dispatch<SetStateAction<UserScopes>>,
  setUserRole: Dispatch<SetStateAction<string>>,
) {
  setUserRole('readonly');

  if (level === 1) {
    setUserScopes(
      userScopes.map((s) => ({
        ...s,
        isAuthorized: ReadOnlyRoleEquivalentScopes.includes(s.scope as BackofficeScope),
      })),
    );
  } else if (level === 2) {
    setUserScopes(
      userScopes.map((s) => ({
        ...s,
        isAuthorized: ![
          BackofficeScope.userReadUser,
          BackofficeScope.telematicsReadSensitiveConnectionData,
          BackofficeScope.userWriteCredentials,
        ].includes(s.scope as BackofficeScope),
      })),
    );
  } else if (level === 3) {
    setUserScopes(
      userScopes.map((s) => ({
        ...s,
        isAuthorized: true,
      })),
    );
  }
}

function convertRole(userRole: string) {
  if (userRole === '') {
    return null;
  } else if (userRole === 'admin') {
    return BackofficeRole.admin;
  } else if (userRole === 'readonly') {
    return BackofficeRole.readonly;
  }
  return null;
}
