import { Company } from './entities';
import { UnifiedCompanySignupStatus, UnifiedCompanyRelationshipSignupStatus } from '../lib/graphql';
import { beautifyString } from '../lib/util/string';

type CompanyToCompare = Pick<Company, 'company_name'>;

export const compareCompany = (c1: CompanyToCompare, c2: CompanyToCompare) => {
  return c1.company_name === c2.company_name || c1.company_name === null || c2.company_name === null
    ? 0
    : c1.company_name.localeCompare(c2.company_name);
};

export const mapIdentifierType = (identifierType: string): string => {
  switch (identifierType) {
    case 'VAT_NUMBER':
      return 'VAT';
    case 'USDOT_NUMBER':
      return 'DOT';
    case 'MC_NUMBER':
      return 'MC';
    case 'MISCELLANEOUS':
      return 'MISC ID';
    case 'TAX_IDENTIFICATION_NUMBER':
      return 'TIN';
    default:
      return identifierType;
  }
};

export const getSignupStatusName = (type: UnifiedCompanySignupStatus | UnifiedCompanyRelationshipSignupStatus) => {
  switch (type) {
    case UnifiedCompanySignupStatus.NO_VISIBILITY_SOURCE:
    case UnifiedCompanyRelationshipSignupStatus.NO_VISIBILITY_SOURCE:
      return 'Visibility source pending';
    default:
      return beautifyString(type);
  }
};
