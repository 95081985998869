import { notNil } from '@sixfold/typed-primitives';
import React, { FC, useState } from 'react';
import { Button, Grid, Image, Label } from 'semantic-ui-react';

import { CompanyAssetsQuery } from '../../lib/graphql';

export interface CompanyAssetsViewProps {
  data: {
    company?: CompanyAssetsQuery['company'] | null;
  };
  onAssetUpdate: (file: File | undefined, asset: 'consignee_logo' | 'consignee_background') => Promise<boolean>;
}

const CompanyAsset: FC<{
  onAssetUpdate: (file: File | undefined) => Promise<boolean>;
  url: string | null | undefined;
}> = ({ onAssetUpdate, url }) => {
  const [asset, setAsset] = useState<File | null>(null);
  const [isUpdatingAsset, setIsUpdatingAsset] = useState<boolean>(false);
  const [isRemovingAsset, setIsRemovingAsset] = useState<boolean>(false);

  // As file input is only uncontrolled, the value of it can not be reset after the upload
  // as such, use a workaround to force React to recreate it by changing its 'key' prop
  const [fileKey, setFileKey] = useState<number>(Math.random());

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setIsUpdatingAsset(true);
          if (asset !== null) {
            if (await onAssetUpdate(asset)) {
              setFileKey(Math.random());
              setAsset(null);
            }
          }
          setIsUpdatingAsset(false);
        }}>
        <input
          key={fileKey}
          type="file"
          accept=".jpg,.png,.svg,.jpeg,.gif"
          onChange={(event) => {
            if (event.target !== null && event.target.files !== null && event.target.validity.valid) {
              setAsset(event.target.files[0]);
            }
          }}
        />
        <Button
          type="submit"
          size="tiny"
          primary
          loading={isUpdatingAsset}
          disabled={isUpdatingAsset || isRemovingAsset || asset === null}>
          Update
        </Button>
        <Button
          type="button"
          size="tiny"
          secondary
          negative
          loading={isRemovingAsset}
          disabled={isUpdatingAsset || isRemovingAsset || url === null}
          onClick={async () => {
            setIsRemovingAsset(true);
            if (await onAssetUpdate(undefined)) {
              setFileKey(Math.random());
              setAsset(null);
            }
            setIsRemovingAsset(false);
          }}>
          Remove
        </Button>
      </form>
      <div style={{ marginTop: '10px' }}>
        {notNil(url) ? (
          <>
            <a href={url} target="_blank" rel="noreferrer">
              View full size
            </a>
            <Image bordered size="small" src={url} />
          </>
        ) : (
          <Label>No image</Label>
        )}
      </div>
    </>
  );
};

export const CompanyAssetsView: FC<CompanyAssetsViewProps> = ({ data, onAssetUpdate }) => (
  <>
    <h2>Assets</h2>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <h3>Consignee background</h3>
          <CompanyAsset
            url={data.company?.assets.consigneeBackground?.url}
            onAssetUpdate={async (file) => {
              return await onAssetUpdate(file, 'consignee_background');
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <h3>Consignee logo</h3>
          <CompanyAsset
            url={data.company?.assets.consigneeLogo?.url}
            onAssetUpdate={async (file) => {
              return await onAssetUpdate(file, 'consignee_logo');
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);
