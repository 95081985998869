import { ErrorHandler } from '@sixfold/app-utils';
import { AggregateError, notNil } from '@sixfold/typed-primitives';
import { InMemoryCache, IntrospectionFragmentMatcher, IntrospectionResultData, IdGetter } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Operation } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ServerError, ServerParseError } from 'apollo-link-http-common';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';

import { underscore, pascalToCamelCase } from '../util/string';

export function dataIdFromObject(object: Record<string, unknown>): string {
  const typename = object.__typename as string;
  const snakeCaseTypename = underscore(typename);
  const snakeCaseIdFieldName = `${snakeCaseTypename}_id`;

  const camelCaseTypename = pascalToCamelCase(typename);
  const camelCaseIdFieldName = `${camelCaseTypename}Id`;

  if (object[camelCaseIdFieldName] !== undefined) {
    return `${snakeCaseTypename}:${object[camelCaseIdFieldName]}`;
  }

  if (object[snakeCaseIdFieldName] !== undefined) {
    return `${snakeCaseTypename}:${object[snakeCaseIdFieldName]}`;
  }

  return object.id as string;
}

const fragmentMatcherData: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'Geofence',
        possibleTypes: [{ name: 'GeofenceCircle' }, { name: 'GeofencePolygon' }],
      },
    ],
  },
};

export const createApolloClient = ({
  handleError,
  onAuthenticationError,
  onGraphQLError,
}: {
  onAuthenticationError: (error: Error) => void;
  onGraphQLError: (aggregatedError: string) => void;
  handleError: ErrorHandler | undefined;
}) => {
  const uploadLink = createUploadLink({ uri: '/graphql', headers: { 'apollo-require-preflight': true } });

  const link = ApolloLink.from([
    onError(({ operation, graphQLErrors, networkError }) => {
      // We can have an auth error two ways, either a 401 from API, or
      // 'Authentication Error' as a GraphQL error
      const networkAuthError =
        networkError !== undefined && isErrorWithStatusCode(networkError) && networkError.statusCode === 401
          ? networkError
          : undefined;
      const authError = networkAuthError ?? graphQLErrors?.find((error) => error.message === 'Authentication Error');

      if (authError !== undefined) {
        onAuthenticationError(authError);
      } else {
        reportNetworkError(operation, networkError, handleError);
        reportGraphQLErrors(operation, graphQLErrors, handleError);
      }

      const aggregatedError = new AggregateError(
        [...(graphQLErrors !== undefined ? graphQLErrors : []), networkError].filter(notNil),
      );

      onGraphQLError(aggregatedError.message);
    }),
    uploadLink,
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      dataIdFromObject: dataIdFromObject as IdGetter,
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: fragmentMatcherData,
      }),
    }),
  });
};

function reportGraphQLErrors(
  operation: Operation,
  errors: ReadonlyArray<GraphQLError> | undefined,
  handleError: ErrorHandler | undefined,
) {
  if (errors === undefined || errors.length === 0) {
    return;
  }

  const aggregatedError = new AggregateError([...errors]);
  console.error('GraphQL error', aggregatedError);

  handleError?.captureError(aggregatedError, {
    extra: {
      errors: errors.map(({ message, source, locations }) => ({
        message,
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
        source: {
          body: source !== undefined ? source.body : undefined,
          name: source !== undefined ? source.name : undefined,
        },
        locations: locations !== undefined ? locations.map(({ line, column }) => ({ line, column })) : undefined,
      })),
    },
  });
}

function reportNetworkError(
  operation: Operation,
  error: Error | ServerError | ServerParseError | undefined,
  handleError: ErrorHandler | undefined,
) {
  if (error === undefined) {
    return;
  }

  const { response } = operation.getContext();
  if (response?.status === 401) {
    return;
  }

  handleError?.captureError(error, {
    extra: {
      operationName: operation.operationName,
      variables: operation.variables,
      extensions: operation.extensions,
    },
  });
}

function isErrorWithStatusCode(error: Error | ServerParseError | ServerError): error is ServerParseError | ServerError {
  return (error as ServerParseError | ServerError).statusCode !== undefined;
}
