import { Text } from '@sixfold/common-ui';
import { BackofficeScope } from '@sixfold/session-interface';
import { Table, SortBy } from '@sixfold/table-component';
import { isNil, notNil } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import { differenceInMinutes, formatDistanceStrict, intlFormatDistance } from 'date-fns';
import DOMPurify from 'dompurify';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Grid, GridColumn, GridRow, Header, Icon, Popup } from 'semantic-ui-react';

import { ConfirmButton } from '../../components/confirm_button';
import { ScrollableMenu } from '../../components/data_to_list';
import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { emptyTableState, getTableHeaderClassNames } from '../../components/table';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import { getEmbedConfig } from '../../lib/data';
import { IntegrationSyncResultResultType, TelematicsIntegrationDiagnosticStatus } from '../../lib/graphql';
import { useUrlSort } from '../../lib/util/url';
import { Routes } from '../../routes';
import { IntegrationCredentialsList } from '../containers/integration_credentials_list_container';
import { ValidateIntegrationButton } from '../containers/telematics_validation_container';
import { IntegrationSyncHistory, TelematicIntegration, UserProfile } from '../entities';

export interface IntegrationListProps {
  integrations: TelematicIntegration[] | null;
}

interface Props extends IntegrationListProps {
  deleteTelematicsIntegration?: (telematicsIntegrationId: string) => Promise<void>;
  hideCredentials?: boolean;
}

class IntegrationTable extends Table<TelematicIntegration> {}

export type SortableColumn = (typeof sortableColumns)[number];
const sortableColumns = [
  'telematics_integration_id',
  'telematics_provider_id',
  'company_name',
  'createdAt',
  'updatedAt',
] as const;

export const useIntegrationListSort = () =>
  useUrlSort<SortableColumn>({
    key: 'telematics_provider_id',
    direction: 'ASC',
  });

export const IntegrationList: React.FunctionComponent<Props> = ({
  integrations,
  hideCredentials = false,
  deleteTelematicsIntegration,
}) => {
  const isAdmin = useIsAdmin();
  const hasIntegrationsWritePermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);
  const showForPrivilegedUser = isAdmin || hasIntegrationsWritePermission;

  const { sort, changeSort } = useIntegrationListSort();

  const onSortByChange = (newSortBy: SortBy) => {
    if (sortableColumns.includes(newSortBy.keyPath as SortableColumn)) {
      changeSort({
        key: newSortBy.keyPath as SortableColumn,
        direction: newSortBy.value,
      });
    }
  };

  const integrationsTableHeaderColumns: { keyPath?: string; value: string }[] = [
    {
      keyPath: 'telematics_integration_id',
      value: '#',
    },
    {
      keyPath: 'telematics_provider_id',
      value: 'Provider ID',
    },
    {
      keyPath: 'company_name',
      value: 'Company',
    },
    {
      keyPath: 'company_integration_id',
      value: 'Company integration ID',
    },
    {
      keyPath: 'createdAt',
      value: 'Created at',
    },
    {
      keyPath: 'updatedAt',
      value: 'Updated at',
    },
    hideCredentials ? null : { value: 'Credentials' },
    {
      keyPath: 'enabled',
      value: 'Enabled',
    },
    {
      keyPath: 'syncHistory',
      value: 'Sync history',
    },
    showForPrivilegedUser ? { value: 'Debug' } : null,
    {
      keyPath: 'normalizedDiagnosticStatus',
      value: 'Diagnostic status',
    },
    showForPrivilegedUser ? { value: 'Credentials' } : null,
    showForPrivilegedUser && deleteTelematicsIntegration !== undefined ? { value: 'Delete' } : null,
  ].filter(notNil);

  const normalizedIntegrations =
    integrations !== null
      ? integrations.map((integration) => ({
          ...integration,
          normalizedDiagnosticStatus: integration?.diagnosticStatus ?? '',
        }))
      : [];

  return (
    <div className="telematics__integration-list">
      <IntegrationTable
        className="ui very basic sortable unstackable table"
        tableHeaders={{
          defaultClassName: getTableHeaderClassNames,
          columns: integrationsTableHeaderColumns,
        }}
        data={normalizedIntegrations}
        sortBy={{
          keyPath: sort.key,
          value: sort.direction,
        }}
        onSortByChange={onSortByChange}
        emptyStatePlaceholder={emptyTableState('No integrations')}>
        {({ row }) => {
          const {
            company_integration_id,
            company_id,
            company: { company_name },
            telematics_integration_id,
            telematics_provider_id,
            telematicsProvider,
            connection_data,
            enabled,
            diagnosticStatus,
            diagnosticComment,
            createdAt,
            updatedAt,
            isDeletable,
            createdBy,
            updatedBy,
            integrationApiDebugUrl,
            integrationSyncHistory,
          } = row.data;

          const lastHistoricalResult: IntegrationSyncHistory | undefined = integrationSyncHistory
            ?.filter((res) => res.resultType === IntegrationSyncResultResultType.HISTORICAL_RESULT)
            .at(-1);
          const lastSuccess: IntegrationSyncHistory | undefined = integrationSyncHistory
            ?.filter((res) => isNil(res.errorType))
            .at(-1);
          const lastErrorDescription = integrationSyncHistory?.at(-1)?.errorDescription ?? undefined;

          return (
            <tr key={telematics_integration_id}>
              <td>
                <NavLink
                  to={Routes.CompanyTelematicsIntegration.generatePath({
                    company_id,
                    telematics_integration_id,
                  })}>
                  {telematics_integration_id}
                </NavLink>
              </td>
              <td>
                <NavLink to={Routes.TelematicsProvider.generatePath({ provider_id: telematics_provider_id })}>
                  {telematics_provider_id}
                </NavLink>
              </td>
              <td>
                <NavLink to={Routes.CompanyTelematicsIntegrations.generatePath({ company_id })}>
                  {company_name ?? company_id}
                </NavLink>
              </td>
              <td>{company_integration_id}</td>
              <td>
                <div>
                  <FormattedDate date={createdAt} />
                </div>
                {userLink(createdBy)}
              </td>
              <td>
                <div>
                  <FormattedDate date={updatedAt} />
                </div>
                {userLink(updatedBy)}
              </td>
              {!hideCredentials && (
                <td>
                  {telematicsProvider !== null && telematicsProvider.connectionParameters !== null ? (
                    <IntegrationCredentialsList
                      integrationId={telematics_integration_id}
                      connectionData={connection_data ?? {}}
                      connectionParameters={telematicsProvider.connectionParameters}></IntegrationCredentialsList>
                  ) : null}
                </td>
              )}
              <td>{getEnabledFlag(enabled, telematicsProvider?.isDeprecated ?? false)}</td>
              <td>
                <div className="sync-history">
                  {integrationSyncHistory?.map((syncHistory, idx) => {
                    return (
                      <Popup
                        hoverable
                        positionFixed
                        key={idx}
                        style={{ backgroundColor: '#F8F8F8' }}
                        trigger={
                          <div className="sync-history__result">
                            <img
                              src={`${getEmbedConfig()?.base_assets_url ?? ''}/assets/${getIcon(syncHistory)}`}
                              alt="Icon"></img>
                            {syncHistory.startTimeAt === lastHistoricalResult?.startTimeAt && (
                              <span className={'sync-history__result-historyflag'}>»</span>
                            )}
                          </div>
                        }>
                        <Grid style={{ minWidth: '300px' }}>
                          <GridRow>
                            <GridColumn textAlign="center">
                              <p>
                                <strong className="bold-text">When: </strong>
                                {formatSecondsDuration(syncHistory.startTimeAt, new Date().toISOString())}
                              </p>
                              <p>
                                <strong className="bold-text">StartTime: </strong>
                                {syncHistory.startTimeAt}
                              </p>
                              <p>
                                <strong className="bold-text">EndTime: </strong>
                                {syncHistory.endTimeAt}
                              </p>
                              <p>
                                <strong className="bold-text">Duration:</strong>{' '}
                                {formatDistanceStrict(
                                  new Date(syncHistory.endTimeAt),
                                  new Date(syncHistory.startTimeAt),
                                )}
                              </p>
                              <br />
                              {syncHistory.vehicleCount !== null && (
                                <p>
                                  <strong className="bold-text">Vehicles: </strong>
                                  {syncHistory.vehicleCount}
                                </p>
                              )}
                              {syncHistory.statusCount !== null && (
                                <p>
                                  <strong className="bold-text">Statuses: </strong>
                                  {syncHistory.statusCount}
                                </p>
                              )}
                              {syncHistory.temperatureCount !== null && (
                                <p>
                                  <strong className="bold-text">Temperatures: </strong>
                                  {syncHistory.temperatureCount}
                                </p>
                              )}
                              {syncHistory.distinctVehicleStatusFutureCount !== null &&
                                syncHistory.distinctVehicleStatusFutureCount > 0 && (
                                  <p>
                                    <strong className="bold-text">Vehicles in future (Status): </strong>
                                    {syncHistory.distinctVehicleStatusFutureCount}
                                  </p>
                                )}
                              {syncHistory.distinctVehicleTemperatureFutureCount !== null &&
                                syncHistory.distinctVehicleTemperatureFutureCount > 0 && (
                                  <p>
                                    <strong className="bold-text">Vehicles in future (Temperature): </strong>
                                    {syncHistory.distinctVehicleTemperatureFutureCount}
                                  </p>
                                )}
                              {syncHistory.distinctVehicleNullIslandCount !== null &&
                                syncHistory.distinctVehicleNullIslandCount > 0 && (
                                  <p>
                                    <strong className="bold-text">Null island coordinates: </strong>
                                    {syncHistory.distinctVehicleNullIslandCount}
                                  </p>
                                )}
                              {syncHistory.vehicleAmbiguityCount !== null && syncHistory.vehicleAmbiguityCount > 0 && (
                                <p>Vehicles discarded from being ambiguous: {syncHistory.vehicleAmbiguityCount}</p>
                              )}
                              {syncHistory.latestStatusTimestamp !== null && (
                                <p>
                                  <strong className="bold-text">Latest status freshness:</strong>{' '}
                                  {formatSecondsDuration(syncHistory.latestStatusTimestamp, syncHistory.endTimeAt)}
                                </p>
                              )}
                              {syncHistory.latestTemperatureTimestamp !== null && (
                                <p>
                                  <strong className="bold-text">Latest temperature freshness:</strong>{' '}
                                  {formatSecondsDuration(syncHistory.latestTemperatureTimestamp, syncHistory.endTimeAt)}
                                </p>
                              )}
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn>
                              {(syncHistory.errorType !== null || syncHistory.errorDescription !== null) && (
                                <Header color="red">ERROR</Header>
                              )}
                              {syncHistory.errorType !== null && (
                                <p>
                                  <strong className="bold-text">Type: </strong>
                                  {syncHistory.errorType}
                                </p>
                              )}
                              {syncHistory.errorDescription !== null && (
                                <p className="sync-history__errorDescription">
                                  <strong className="bold-text">Description: </strong>
                                  {syncHistory.errorDescription}
                                </p>
                              )}
                            </GridColumn>
                          </GridRow>
                        </Grid>
                      </Popup>
                    );
                  })}
                </div>
                {notNil(lastSuccess) &&
                  lastSuccess.resultType === IntegrationSyncResultResultType.HISTORICAL_RESULT && (
                    <div>
                      <br />
                      <p>
                        <Text weight="bold">Last success:</Text>{' '}
                        {formatSecondsDuration(lastSuccess.startTimeAt, new Date().toISOString())}
                      </p>
                    </div>
                  )}
                {notNil(lastErrorDescription) && (
                  <div>
                    {(isNil(lastSuccess) ||
                      lastSuccess.resultType !== IntegrationSyncResultResultType.HISTORICAL_RESULT) && <br />}
                    <p className="sync-history__lastError">
                      <Text weight="bold">Last error:</Text> {lastErrorDescription}
                    </p>
                  </div>
                )}
              </td>
              {showForPrivilegedUser && (
                <td>
                  <Button
                    icon
                    labelPosition="right"
                    size="tiny"
                    primary
                    as="a"
                    href={integrationApiDebugUrl}
                    target="_blank"
                    rel="noopener noreferrer">
                    Debug
                    <Icon name="bug" />
                  </Button>
                </td>
              )}
              <td>
                {diagnosticStatus !== null ? (
                  <div style={{ maxWidth: '200px' }}>
                    <div
                      className={classnames(
                        'ui',
                        'label',
                        diagnosticStatus === TelematicsIntegrationDiagnosticStatus.INTERMITTENT ? 'yellow' : 'orange',
                      )}
                      style={{ cursor: 'help' }}>
                      {diagnosticStatus}
                    </div>
                    <br />
                    <ScrollableMenu data={embedURLsInText(diagnosticComment ?? '')} />
                  </div>
                ) : (
                  ''
                )}
              </td>
              {showForPrivilegedUser && (
                <td>
                  {telematics_provider_id !== undefined && notNil(connection_data) && (
                    <ValidateIntegrationButton integration={{ telematics_provider_id, telematics_integration_id }} />
                  )}
                </td>
              )}
              {showForPrivilegedUser && deleteTelematicsIntegration !== undefined && (
                <td>
                  <ConfirmButton
                    size="tiny"
                    initialButtonColor="red"
                    label="Delete"
                    disabled={!isDeletable}
                    onConfirm={async () => {
                      await deleteTelematicsIntegration(telematics_integration_id);
                    }}
                  />
                </td>
              )}
            </tr>
          );
        }}
      </IntegrationTable>
    </div>
  );
};

function getIcon(result: IntegrationSyncHistory) {
  const {
    errorType,
    vehicleCount,
    temperatureCount,
    statusCount,
    distinctVehicleStatusFutureCount,
    latestStatusTimestamp,
    latestTemperatureTimestamp,
  } = result;

  if (errorType !== null) {
    switch (errorType) {
      case 'GLOBAL_TIMEOUT':
        return 'sync_timeout_error23x23.png';
      case 'UNAUTHORIZED':
        return 'sync_authentication_error23x23.png';
      case 'RATE_LIMIT':
        return 'sync_ratelimit_error23x23.png';
      default:
        return 'sync_unknown_error23x23.png';
    }
  }

  let typeCount = 3;
  if (vehicleCount === null || vehicleCount === 0) {
    typeCount -= 1;
  }
  if (statusCount === null || statusCount === 0) {
    typeCount -= 1;
  }
  if (temperatureCount === null || temperatureCount === 0) {
    typeCount -= 1;
  }

  const endTimeAt = new Date(result.endTimeAt);

  const statusesStale =
    latestStatusTimestamp !== null && differenceInMinutes(endTimeAt, new Date(latestStatusTimestamp)) > 30;
  const temperaturesStale =
    latestTemperatureTimestamp !== null && differenceInMinutes(endTimeAt, new Date(latestTemperatureTimestamp)) > 30;

  if (typeCount === 1) {
    if (vehicleCount !== null && vehicleCount > 0) {
      return 'sync_success_vehicles23x23.png';
    }
    if (statusCount !== null && statusCount > 0) {
      if (distinctVehicleStatusFutureCount !== null && distinctVehicleStatusFutureCount > 0) {
        return 'sync_success_timestamp_future23x23.png';
      }
      if (statusesStale) {
        return 'sync_success_statuses_stale23x23.png';
      }
      return 'sync_success_statuses23x23.png';
    }
    if (temperatureCount !== null && temperatureCount > 0) {
      if (temperaturesStale) {
        return 'sync_success_temperatures_stale23x23.png';
      }
      return 'sync_success_temperatures23x23.png';
    }
  }

  if (typeCount === 0) {
    return 'sync_nodata23x23.png';
  }

  if (distinctVehicleStatusFutureCount !== null && distinctVehicleStatusFutureCount > 0) {
    return 'sync_success_timestamp_future23x23.png';
  }

  if (statusesStale) {
    return 'sync_success_stale23x23.png';
  }

  return 'sync_success23x23.png';
}

function formatSecondsDuration(a: string | number, b: string | number): string {
  return intlFormatDistance(new Date(a), new Date(b));
}

function getEnabledFlag(enabled: boolean, isDeprecated: boolean) {
  const text = enabled ? 'Enabled' : 'Disabled';
  const color = isDeprecated ? 'gray' : enabled ? 'green' : 'red';
  return <div className={classnames('ui', color, 'label')}>{text}</div>;
}

function userLink(user: UserProfile | null) {
  if (isNil(user)) {
    return null;
  }

  if (isNil(user.first_name) && isNil(user.last_name)) {
    return null;
  }

  return (
    <div>
      {' by '}
      <NavLink
        to={Routes.User.generatePath({
          user_id: user.user_id,
        })}>{`${user.first_name ?? ''} ${user.last_name ?? ''}`}</NavLink>
    </div>
  );
}

export const embedURLsInText = (text: string) => {
  const urlInTextRegex = /(https?:\/\/[^\s]+)/g;
  const sanitizedText = DOMPurify.sanitize(text);

  const parts = sanitizedText.split(urlInTextRegex);

  const formattedText = parts.map((part, index) =>
    urlInTextRegex.test(part) ? (
      <a key={index} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    ),
  );

  return formattedText;
};
