import React from 'react';
import ReactJson from 'react-json-view';
import { Button, Icon, List, Table } from 'semantic-ui-react';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { PlatformCallback, Tour } from '../entities';

export interface PlatformCallbackListProps {
  callbacks: PlatformCallback[];
}

const PlatformCallbackComponent: React.SFC<{ callback: PlatformCallback }> = ({ callback }) => {
  const [showsPayload, setShowsPayload] = React.useState<boolean>(false);
  const { platform, subjectId, subjectType, createdAt } = callback;

  return (
    <Table compact="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {platform?.platform_name ?? 'Unknown platform'} - {subjectType}:{subjectId} -{' '}
            <FormattedDate date={createdAt} />
            <Button floated="right" size="mini" onClick={() => setShowsPayload(!showsPayload)}>
              {showsPayload ? 'Hide payload' : 'Show payload'}
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {showsPayload && (
          <Table.Row>
            <Table.Cell>
              <div
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  backgroundColor: '#d5e0e630',
                  padding: '8px',
                }}>
                <ReactJson
                  src={callback.payload}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  indentWidth={2}
                  shouldCollapse={(field) => {
                    if (field.namespace.length === 1 && field.namespace[0] === 'root') {
                      return false;
                    }
                    return false;
                  }}
                />
              </div>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export const PlatformCallbackList: React.StatelessComponent<{
  tour: Pick<Tour, 'platformCallbacks' | 'isSendEtaToPlatformEnabled'>;
}> = ({ tour: { platformCallbacks, isSendEtaToPlatformEnabled } }) => (
  <>
    <List horizontal>
      <List.Item className={'tour__feature__flag'}>
        <h3>Latest callbacks</h3>
      </List.Item>
      {!isSendEtaToPlatformEnabled && (
        <>
          <List.Item>
            <Icon bordered inverted color={'yellow'} name={'exclamation'} />
          </List.Item>
          <List.Item className={'tour__feature__flag'}>Sending ETA to platform not enabled!</List.Item>
        </>
      )}
    </List>

    {platformCallbacks.length === 0 ? (
      <Table>
        <Table.Body>
          <Table.Row textAlign="center">
            <Table.Cell>No callbacks</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    ) : (
      platformCallbacks.map((callback) => (
        <PlatformCallbackComponent
          key={`${callback.platform !== null && callback.platform.platform_id}:${callback.subjectType}:${
            callback.subjectId
          }`}
          callback={callback}
        />
      ))
    )}
  </>
);
