import { DagreLayout, OutNode } from '@antv/layout';
import { isNil, notNil, omit } from '@sixfold/typed-primitives';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Flag, FlagNameValues, Icon, Popup, Table } from 'semantic-ui-react';

import { FormattedDate } from '../../components/date_formatting/formatted_date';
import { TripDeliveryItem, TripMilestone } from '../../lib/graphql';
import { TripLocation, TripSegment } from '../entities';

import styles from './tour_trips.module.css';

export interface TourTripViewProps {
  segments: TripSegment[];
  locations: TripLocation[];
  deliveries: TripDeliveryItem[];
}

type TripWithoutLocations = Omit<TripSegment, 'locations'>;
type TripNode = TripLocation & {
  milestones: (TripMilestone & { trip: TripWithoutLocations })[];
};

type TripLeg = { trip: TripWithoutLocations; source: TripNode; target: TripNode };

/**
 * Renders a graph of locations and trips between them.
 *
 * Underlying idea for this rendering is to use a Dagre graph layout algorithm, but in a relative instead of absolute way.
 *
 * To achieve this, instead of using raw Dagre output, which is limited to fixed size nodes and outputs absolute positions, we calculate CSS grid positions for the elements.
 * This results in a graph that is well layout, but allows different size html nodes.
 *
 * The graph is rendered using CSS grid and SVG lines.
 */
export const TripGraph: React.FC<TourTripViewProps> = ({ segments, locations }) => {
  const elementRefs = useRef(new Map<string, HTMLElement>());
  const parentRef = useRef<HTMLDivElement>(null);

  const [tripLines, setTripLines] = useState<{ x1: number; x2: number; y1: number; y2: number; trip: TripLeg }[]>([]);
  const [graphData] = useState(createLocationGraph(segments, locations));
  const [tripNodes, setTripNodes] = useState<OutNode[] | undefined>(undefined);
  const [selectedNode, setSelectedNode] = useState<TripNode | undefined>(undefined);

  useEffect(() => {
    layoutGraph(graphData);
    setTripNodes(layoutGraph(graphData));
  }, [graphData]);

  useEffect(() => {
    const drawArrows = () => {
      if (isNil(parentRef.current) || elementRefs.current.size === 0) {
        return;
      }

      const parentElement = parentRef.current;

      const newLines = graphData.legs.map((leg) => {
        const currentElement = elementRefs.current.get(leg.source.id);
        const nextElement = elementRefs.current.get(leg.target.id);

        if (currentElement === undefined || nextElement === undefined) {
          return undefined;
        }

        const pos1 = getElementBottom(currentElement, parentElement);
        const pos2 = getElementTop(nextElement, parentElement);
        return { x1: pos1.x, y1: pos1.y, x2: pos2.x, y2: pos2.y, trip: leg };
      });
      setTripLines(newLines.filter(notNil));
    };

    window.addEventListener('resize', drawArrows);

    drawArrows();

    return () => {
      window.removeEventListener('resize', drawArrows);
    };
  }, [tripNodes, graphData.legs, selectedNode]);

  return (
    <div className={styles.tour_tree_wrapper}>
      <svg className="svg-container">
        <defs>
          <marker id="circle" markerWidth="4" markerHeight="4" refX="2" refY="2" orient="auto">
            <circle cx="2" cy="2" r="1" />
          </marker>
        </defs>

        {tripLines.map((line, index) => (
          <g key={index}>
            <line
              x1={line.x1}
              y1={line.y1}
              x2={line.x2}
              y2={line.y2}
              stroke={hashToCssColor(line.trip.trip.id)}
              markerEnd="url(#circle)"
              markerStart="url(#circle)"
            />
            <text x={line.x1 + 5 + (line.x2 - line.x1) / 2} y={line.y1 + (line.y2 - line.y1) / 2}>
              {line.trip.trip.transportMethod?.name}
            </text>
          </g>
        ))}
      </svg>

      <div className={styles.tour_tree} ref={parentRef}>
        {(tripNodes ?? []).map((outNode) => {
          const node = graphData.nodes.find(({ id }) => id === outNode.id);

          if (node === undefined) {
            return null;
          }

          return (
            <div
              key={node.id}
              style={{ gridRow: `${outNode.y} / ${outNode.y}`, gridColumn: `${outNode.x} / ${outNode.x}` }}
              className={classnames(styles.location, { [styles.active]: selectedNode === node })}
              ref={(el) => elementRefs.current.set(node.id, el!)}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                selectedNode === node ? setSelectedNode(undefined) : setSelectedNode(node);
              }}>
              <div className={styles.location__name}>
                <div>
                  <Popup content={node.type} trigger={locationTypeToIcon(node.type)}></Popup>
                </div>
                <div>{node.name}</div>
                <div className={styles.location__flag}>{getFlagIcon(node.country)}</div>
              </div>

              <div className={styles.location__milestones}>
                {node.milestones.map((milestone) => (
                  <div className={styles.milestone} key={milestone.trip.id + milestone.qualifier}>
                    <div className={styles.milestone__header}>
                      <div className={styles.milestone__color} style={{ color: hashToCssColor(milestone.trip.id) }}>
                        <div
                          className={classnames(styles.milestone__color_status, {
                            [styles.milestone__arrived]: notNil(milestone.actualTime),
                          })}></div>
                      </div>
                      <div className={styles.milestone__qualifier}>{milestone.name}</div>
                      <div className={styles.milestone__date}>{renderActiveDate(milestone)}</div>
                    </div>
                    {selectedNode === node && (
                      <div className={styles.milestone__details}>
                        {milestone.actualTime && (
                          <div>
                            A:
                            <FormattedDate
                              date={milestone.actualTime?.dateTime}
                              propsTimezone={milestone.actualTime?.timezone}
                            />
                          </div>
                        )}
                        {milestone.estimatedTime && (
                          <div>
                            E:
                            <FormattedDate
                              date={milestone.estimatedTime?.dateTime}
                              propsTimezone={milestone.estimatedTime?.timezone}
                            />
                          </div>
                        )}
                        {milestone.scheduledTime && (
                          <div>
                            P:
                            <FormattedDate
                              date={milestone.scheduledTime?.dateTime}
                              propsTimezone={milestone.scheduledTime?.timezone}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TourTripView: React.FC<TourTripViewProps> = ({ segments, locations, deliveries }) => {
  return (
    <>
      <h4>Trips</h4>

      <Table className={styles.tour_trips} compact="very" size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Color</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Identifiers</Table.HeaderCell>
            <Table.HeaderCell>From </Table.HeaderCell>
            <Table.HeaderCell>To</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {segments.map((segment, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>
                <div className={styles.color_swatch} style={{ background: hashToCssColor(segment.id) }}></div>
              </Table.Cell>

              <Table.Cell>{segment.transportMethod?.mode}</Table.Cell>
              <Table.Cell>{segment.transportMethod?.name}</Table.Cell>
              <Table.Cell>
                {segment.transportMethod?.identifiers?.map((id) => `${id.type}: ${id.value}`).join(', ')}
              </Table.Cell>
              <Table.Cell>{getFirstLocation(segment)?.name}</Table.Cell>
              <Table.Cell>{getLastLocation(segment)?.name}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h5>Deliveries</h5>

      <Table className={styles.tour_trips} compact="very" size="small" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {deliveries.map((delivery, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{delivery.type}</Table.Cell>
              <Table.Cell>{delivery.name}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h5>Overview</h5>

      <TripGraph segments={segments} locations={locations} deliveries={deliveries}></TripGraph>
    </>
  );
};

function locationTypeToIcon(mode: NonNullable<TripLocation['type']> | null) {
  switch (mode) {
    case 'DESTINATION':
      return <Icon className="sign-in" />;
    case 'ORIGIN':
      return <Icon className="sign-out" />;
    case 'OTHER':
      return <Icon className="question" />;
    case 'EMPTY_POD':
    case 'EMPTY_POL':
    case 'PORT_OF_DISCHARGE':
    case 'PORT_OF_LOADING':
    case 'TRANSSHIPMENT_PORT':
      return <Icon className="anchor" />;
    default:
      return <Icon className="question" />;
  }
}

function renderActiveDate(milestone: TripMilestone) {
  if (milestone.actualTime?.dateTime !== undefined) {
    return (
      <div>
        A: <FormattedDate date={milestone.actualTime?.dateTime} showTime={false} />
      </div>
    );
  }
  if (milestone.estimatedTime?.dateTime !== undefined) {
    return (
      <div>
        E: <FormattedDate date={milestone.estimatedTime?.dateTime} showTime={false} />
      </div>
    );
  }
  if (milestone.scheduledTime?.dateTime !== undefined) {
    return (
      <div>
        P: <FormattedDate date={milestone.scheduledTime?.dateTime} showTime={false} />
      </div>
    );
  }

  return <> </>;
}

function indexToCssColor(idx: number): string {
  const hue = (idx * 137.508) % 360;
  return `hsl(${hue}, 50%, 50%)`;
}

function hashToCssColor(hash: string): string {
  let hashInt = 0;

  for (let i = 0; i < hash.length; i++) {
    hashInt += hash.charCodeAt(i);
  }

  return indexToCssColor(hashInt);
}

function getFirstLocation(trip: TripSegment | undefined): TripLocation | undefined {
  if (trip === undefined || trip.locations === null) {
    return undefined;
  }

  return trip.locations[0].location ?? undefined;
}

function getLastLocation(trip: TripSegment | undefined): TripLocation | undefined {
  if (trip === undefined || trip.locations === null) {
    return undefined;
  }

  return trip.locations[trip.locations.length - 1].location ?? undefined;
}

const getElementBottom = (element: HTMLElement, parent: HTMLElement) => {
  const parentPos = parent.getBoundingClientRect();
  const rect = element.getBoundingClientRect();
  return {
    x: rect.left - parentPos.left + rect.width / 2,
    y: rect.bottom - parentPos.top,
  };
};

const getElementTop = (element: HTMLElement, parent: HTMLElement) => {
  const parentPos = parent.getBoundingClientRect();
  const rect = element.getBoundingClientRect();
  return {
    x: rect.left - parentPos.left + rect.width / 2,
    y: rect.top - parentPos.top,
  };
};

/**
 * Creates a graph of the trip segments and locations.
 *
 * Joins separate trips and their locations into a single graph by joining the locations.
 * Additionally it  joins the trips by using previousTripIds if locations do not overlap.
 *
 */
function createLocationGraph(segments: TripSegment[], locations: TripLocation[]) {
  const legs: TripLeg[] = [];
  const nodeMap = new Map<string, TripNode>(
    locations.map((location) => [location.id, { ...location, milestones: [] }]),
  );

  for (const segment of segments) {
    let prevLocation: TripNode | undefined = undefined;
    const trip = omit(segment, 'locations');

    for (const location of segment.locations ?? []) {
      if (location.location === null) {
        continue;
      }

      const node = nodeMap.get(location.location.id);
      if (node === undefined) {
        continue;
      }

      node.milestones.push(...location.milestones.map((milestone) => ({ ...milestone, trip })));

      if (prevLocation !== undefined) {
        legs.push({
          trip,
          source: prevLocation,
          target: node,
        });
      } else {
        // connect trips by previousTripIds if locations do not overlap
        for (const prevTripId of segment.previousTripIds ?? []) {
          const prevSegment = segments.find((segment) => segment.id === prevTripId);

          const lastLocation = getFirstLocation(prevSegment);
          const firstLocation = getFirstLocation(segment);

          if (prevSegment === undefined || lastLocation === undefined || firstLocation === undefined) {
            continue;
          }

          const hasConnection = legs.some(
            (leg) =>
              leg.trip.id === prevTripId && leg.source.id === lastLocation.id && leg.target.id === firstLocation.id,
          );

          if (!hasConnection) {
            legs.push({
              trip: { ...omit(prevSegment, 'locations') },
              source: nodeMap.get(lastLocation.id)!,
              target: nodeMap.get(firstLocation.id)!,
            });
          }
        }
      }
      prevLocation = node;
    }
  }

  return { legs, nodes: Array.from(nodeMap.values()) };
}

/**
 * Layouts the graph using dagre but outputs the x and y as normalized grid values.
 *
 * For example, if raw dagre output colums would be at 100, 150, 300, it would normalise the columns to 1, 2, 3.
 *
 */
function layoutGraph(graphData: { legs: TripLeg[]; nodes: TripNode[] }) {
  const dagre = new DagreLayout({
    type: 'dagre',
    align: 'UL',
    rankdir: 'TB',
    begin: [1, 1],
    nodesep: 0,
    ranksep: 0,
    nodesepFunc: () => 0,
    ranksepFunc: () => 0,
    nodeSize: 1,
  });

  const layoutData = {
    nodes: graphData.nodes.map((node) => ({ ...node, x: 0, y: 0 })),
    edges: graphData.legs.map((leg) => ({ ...leg, source: leg.source.id, target: leg.target.id })),
  };

  dagre.layout(layoutData);
  const output = dagre.execute();
  if (output === undefined) {
    return;
  }

  const uniqueSortedXValues = Array.from(new Set(output.nodes.map((node) => node.x))).sort((a, b) => a - b);
  const uniqueSortedYValues = Array.from(new Set(output.nodes.map((node) => node.y))).sort((a, b) => a - b);

  const normalizedNodes = output.nodes.map((node) => ({
    ...node,
    x: uniqueSortedXValues.findIndex((val) => val === node.x) + 1,
    y: uniqueSortedYValues.findIndex((val) => val === node.y) + 1,
  }));

  return normalizedNodes;
}

function getFlagIcon(country: string | null) {
  if (country === null) {
    return null;
  }

  return <Flag name={country.toLowerCase() as FlagNameValues}></Flag>;
}
