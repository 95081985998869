import { Table } from '@sixfold/table-component';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Label } from 'semantic-ui-react';

import { getTableHeaderClassNames } from '../../components/table';
import { useUrlSort } from '../../lib/util/url';

export interface Props {
  telematicsProviders: TableProviderProps[];
}

interface TableProviderProps {
  id: string;
  name: string;
  isExternalConfigurationNeeded: boolean;
  isGlobalAllocationForbidden: boolean | null;
  integrationInstructionsURL: string | null;
  isVehicleManagementEnabled: boolean | null;
  isStandardTelematicsPushProvider: boolean | null;
  isInternalUseOnly: boolean;
  isIntegrationImplemented: boolean;
  isDeprecated: boolean | null;
  numIntegrations: number;
  numActiveIntegrations: number;
  numTotalTrucks: number;
  numActiveTrucks: number;
}

class ProvidersTable extends Table<TableProviderProps> {}

const tableColumns = [
  {
    keyPath: 'name',
    value: 'Name',
  },
  {
    keyPath: 'flags',
    value: 'Flags',
  },
  {
    keyPath: 'numIntegrations',
    value: 'Integrations',
  },
  {
    keyPath: 'numActiveIntegrations',
    value: 'Active Integrations',
  },
  {
    keyPath: 'numTotalTrucks',
    value: 'Total trucks ',
  },
  {
    keyPath: 'numActiveTrucks',
    value: 'Active trucks ',
  },
] as const;

export type SortableColumn = (typeof sortableColumns)[number];
const sortableColumns = [
  'name',
  'numIntegrations',
  'numActiveIntegrations',
  'numTotalTrucks',
  'numActiveTrucks',
] as const;

export const useProvidersListSort = () => useUrlSort<SortableColumn>({ key: 'name', direction: 'ASC' });

export const ProvidersList: React.SFC<Props> = ({ telematicsProviders }) => {
  const { sort, changeSort } = useProvidersListSort();

  return (
    <ProvidersTable
      data={telematicsProviders}
      tableHeaders={{
        columns: [...tableColumns],
        defaultClassName: getTableHeaderClassNames,
      }}
      className="ui very basic sortable unstackable table"
      sortBy={{
        keyPath: sort.key,
        value: sort.direction,
      }}
      onSortByChange={(sortBy) => {
        if (sortableColumns.includes(sortBy.keyPath as SortableColumn)) {
          changeSort({ key: sortBy.keyPath as SortableColumn, direction: sortBy.value });
        }
      }}>
      {({
        row: {
          data: {
            name,
            id,
            isIntegrationImplemented,
            isInternalUseOnly,
            isDeprecated,
            isExternalConfigurationNeeded,
            isGlobalAllocationForbidden,
            isStandardTelematicsPushProvider,
            isVehicleManagementEnabled,
            integrationInstructionsURL,
            numIntegrations,
            numActiveIntegrations,
            numTotalTrucks,
            numActiveTrucks,
          },
          index,
        },
      }) => {
        const providerURL = `/telematics/providers/${id}`;
        return (
          <tr key={index}>
            <td>
              <NavLink to={providerURL}>
                <span>{name}</span>
              </NavLink>
            </td>
            <td>
              {isInternalUseOnly && (
                <Label color="yellow" size="tiny">
                  Internal use only
                </Label>
              )}
              {isIntegrationImplemented && (
                <Label color="purple" size="tiny">
                  Implemented
                </Label>
              )}
              {isDeprecated && (
                <Label color="red" size="tiny">
                  Deprecated
                </Label>
              )}
              {isExternalConfigurationNeeded && (
                <Label color="pink" size="tiny">
                  External configuration needed
                </Label>
              )}
              {isGlobalAllocationForbidden && (
                <Label color="orange" size="tiny">
                  Global allocation forbidden
                </Label>
              )}
              {isVehicleManagementEnabled && (
                <Label color="blue" size="tiny">
                  Vehicle management enabled
                </Label>
              )}
              {isStandardTelematicsPushProvider && (
                <Label color="green" size="tiny">
                  Standard telematics push provider
                </Label>
              )}
              {integrationInstructionsURL !== null && (
                <Label size="tiny">
                  <a style={{ opacity: 1 }} href={integrationInstructionsURL}>
                    Instructions URL
                  </a>
                </Label>
              )}
            </td>
            <td>
              <NavLink to={providerURL}>
                <span>{countOrUnknown(numIntegrations)}</span>
              </NavLink>
            </td>
            <td>
              <NavLink to={providerURL}>
                <span>{countOrUnknown(numActiveIntegrations)}</span>
              </NavLink>
            </td>
            <td>
              <span>{countOrUnknown(numTotalTrucks)}</span>
            </td>
            <td>
              <span>{countOrUnknown(numActiveTrucks)}</span>
            </td>
          </tr>
        );
      }}
    </ProvidersTable>
  );
};

const countOrUnknown = (value: number): string => (value === -1 ? '—' : value.toString());
