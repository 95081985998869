import { Loader } from '@sixfold/loader-container';
import { BackofficeScope } from '@sixfold/session-interface';
import React from 'react';
import { Query } from 'react-apollo';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import { InfiniteScroll } from '../../components/infinitescroll';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  CompanyIntegrationsListQuery,
  CompanyIntegrationsListQueryVariables,
  getNodes,
  loadMoreFromConnection,
  notEmpty,
  PAGINATION_PAGE_SIZE,
  TelematicsIntegrationsListQuery,
  TelematicsIntegrationsListQueryVariables,
} from '../../lib/graphql';
import { Routes } from '../../routes';
import {
  ApiEndpointUsageLogInList,
  ApiEndpointUsageLogsList,
} from '../../telematic_integration/components/api_endpoint_usage_log_list';
import { IntegrationList, useIntegrationListSort } from '../../telematic_integration/components/integration_list';
import { DeleteIntegrationMutation } from '../../telematic_integration/containers/delete_integration_container';
import { sortToGraphql } from '../../telematic_integration/containers/integrations_container';
import { TelematicIntegration } from '../../telematic_integration/entities';
import { telematicsIntegrationsListQuery } from '../../telematic_integration/graphql';
import { companyIntegrationsListQuery } from '../graphql';

export interface ApiEndpointUsageListProps {
  api_endpoint_usage_logs_data: {
    api_endpoint_usage_logs: ApiEndpointUsageLogInList[];
    shipper_tours_count: number;
    carrier_tours_count: number;
  };
  company_name: string | null;
}

export interface ListProps {
  integrations: TelematicIntegration[] | null;
}

class ApiEndpointUsageLoadingContainer extends Loader<
  CompanyIntegrationsListQuery,
  ApiEndpointUsageListProps,
  CompanyIntegrationsListQueryVariables
> {}
class LoadingContainer extends Loader<
  TelematicsIntegrationsListQuery,
  ListProps,
  TelematicsIntegrationsListQueryVariables
> {}
class IntegrationListContainerQuery extends Query<
  TelematicsIntegrationsListQuery,
  TelematicsIntegrationsListQueryVariables
> {}
class ApiEndpointUsageContainerQuery extends Query<
  CompanyIntegrationsListQuery,
  CompanyIntegrationsListQueryVariables
> {}

export const CompanyIntegrationsContainer: React.FunctionComponent<RouteComponentProps<{ company_id: string }>> = ({
  match: {
    params: { company_id },
  },
}) => {
  const hasIntegrationsEditPermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);
  const { sort } = useIntegrationListSort();

  return (
    <React.Fragment>
      <h2>
        Integrations{' '}
        {(useIsAdmin() || hasIntegrationsEditPermission) && (
          <NavLink
            to={Routes.CompanyTelematicsIntegrationCreate.generatePath({ company_id })}
            className="ui button primary tiny">
            Add Integration
          </NavLink>
        )}
      </h2>

      <IntegrationListContainerQuery
        query={telematicsIntegrationsListQuery}
        variables={{
          companyId: company_id,
          limit: PAGINATION_PAGE_SIZE,
          sort: sortToGraphql(sort),
        }}>
        {(integrationsResult) => {
          return (
            <DeleteIntegrationMutation companyId={company_id}>
              {({ deleteTelematicsIntegration }) => (
                <LoadingContainer
                  result={integrationsResult}
                  mapData={({ data }) => ({
                    integrations: getNodes(data.telematicsIntegrations?.integrations),
                    company_id,
                  })}>
                  {({ integrations }) => (
                    <>
                      <InfiniteScroll
                        loadMoreEntries={() =>
                          loadMoreFromConnection(telematicsIntegrationsListQuery, integrationsResult, [
                            'telematicsIntegrations',
                            'integrations',
                          ])
                        }>
                        <IntegrationList
                          integrations={integrations}
                          deleteTelematicsIntegration={deleteTelematicsIntegration}
                        />
                      </InfiniteScroll>
                    </>
                  )}
                </LoadingContainer>
              )}
            </DeleteIntegrationMutation>
          );
        }}
      </IntegrationListContainerQuery>

      <ApiEndpointUsageContainerQuery query={companyIntegrationsListQuery} variables={{ company_id }}>
        {(apiEndpointUsageListResult) => (
          <ApiEndpointUsageLoadingContainer
            result={apiEndpointUsageListResult}
            mapData={({ data }) => ({
              api_endpoint_usage_logs_data: {
                api_endpoint_usage_logs: (
                  data.company?.apiEndpointUsageLogs.map((log) => ({
                    api_implemented_at: log.apiImplementedAt,
                    counters: log.counters,
                    endpoint_id: log.endpointId,
                    first_call_at: log.firstCallAt,
                    last_call_at: log.lastCallAt,
                    integration_identifier: log.integrationIdentifier,
                  })) ?? []
                ).filter(notEmpty),
                shipper_tours_count: data.company?.shipperToursCount ?? 0,
                carrier_tours_count: data.company?.carrierToursCount ?? 0,
              },
              company_id,
              company_name: data.company?.company_name ?? null,
            })}>
            {({ api_endpoint_usage_logs_data, company_name }) => (
              <>
                <div style={{ marginTop: '30px' }}>
                  <h2 style={{ marginBottom: '20px' }}>Visibility API</h2>
                  <ApiEndpointUsageLogsList
                    data={[
                      {
                        company_id,
                        company_name: company_name ?? company_id,
                        api_endpoint_usage_logs_data,
                      },
                    ]}
                    isSingleCompany={true}
                  />
                </div>
              </>
            )}
          </ApiEndpointUsageLoadingContainer>
        )}
      </ApiEndpointUsageContainerQuery>
    </React.Fragment>
  );
};
