import { useURLState } from '@sixfold/app-utils';
import { Stack, Box, Input } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import { OptionalString } from '@sixfold/query-string';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { Button } from 'semantic-ui-react';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  PAGINATION_PAGE_SIZE,
  getNodes,
  loadMoreFromConnection,
  SortDirection,
  TelematicsIntegrationsListQuery,
  TelematicsIntegrationsListQueryVariables,
  TelematicsIntegrationsSortInput,
  TelematicsIntegrationsSortKey,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import {
  IntegrationList,
  IntegrationListProps as IntegrationListProps,
  SortableColumn,
  useIntegrationListSort,
} from '../components/integration_list';
import { telematicsIntegrationsListQuery } from '../graphql';

class IntegrationsListLoadingContainer extends Loader<
  TelematicsIntegrationsListQuery,
  { integrations: IntegrationListProps['integrations']; countMatchingFilter: number },
  object
> {}

class IntegrationsListContainerQuery extends Query<
  TelematicsIntegrationsListQuery,
  TelematicsIntegrationsListQueryVariables
> {}

export const TelematicsIntegrationsContainer: React.FC = () => {
  const [activeFilters, setActiveFilters] = useURLState({
    search: OptionalString,
  });
  const clearFilters = () => setActiveFilters({ search: undefined });

  const { sort } = useIntegrationListSort();

  const debouncedSearch = useDebounce(activeFilters.search, 500);

  return (
    <IntegrationsListContainerQuery
      query={telematicsIntegrationsListQuery}
      variables={{
        limit: PAGINATION_PAGE_SIZE,
        textSearch: debouncedSearch ?? undefined,
        sort: sortToGraphql(sort),
      }}>
      {(result) => (
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Box>
              <Input
                autoFocus
                size="medium"
                name="integrations-search"
                label="Integrations search"
                labelPosition="hidden"
                placeholder="Integrations search..."
                value={activeFilters.search ?? ''}
                onChange={({ value }) => {
                  setActiveFilters({ search: value || undefined });
                }}
              />
            </Box>
            {Object.values(activeFilters).some(notNil) && (
              <Button onClick={clearFilters} size="tiny">
                Clear filters
              </Button>
            )}
          </Stack>

          <IntegrationsListLoadingContainer
            result={result}
            mapData={({ data }) => ({
              integrations: getNodes(data.telematicsIntegrations?.integrations),
              countMatchingFilter: data.telematicsIntegrations?.count ?? 0,
            })}>
            {({ integrations, countMatchingFilter }) => (
              <>
                Showing {integrations?.length ?? 0} out of {countMatchingFilter} integrations
                <InfiniteScroll
                  loadMoreEntries={() =>
                    loadMoreFromConnection(telematicsIntegrationsListQuery, result, [
                      'telematicsIntegrations',
                      'integrations',
                    ])
                  }>
                  <IntegrationList hideCredentials integrations={integrations} />
                </InfiniteScroll>
              </>
            )}
          </IntegrationsListLoadingContainer>
        </Stack>
      )}
    </IntegrationsListContainerQuery>
  );
};

export const sortToGraphql = (
  sort: ReturnType<typeof useIntegrationListSort>['sort'],
): TelematicsIntegrationsSortInput => {
  return {
    key: sortToGraphqlMap[sort.key],
    direction: sort.direction === 'ASC' ? SortDirection.ASCENDING : SortDirection.DESCENDING,
  };
};

const sortToGraphqlMap = {
  telematics_integration_id: TelematicsIntegrationsSortKey.INTEGRATION_ID,
  telematics_provider_id: TelematicsIntegrationsSortKey.PROVIDER_ID,
  company_name: TelematicsIntegrationsSortKey.COMPANY_NAME,
  createdAt: TelematicsIntegrationsSortKey.CREATED_AT,
  updatedAt: TelematicsIntegrationsSortKey.UPDATED_AT,
} satisfies {
  [K in SortableColumn]: TelematicsIntegrationsSortInput['key'];
};
