import { ErrorHandlerProvider, ErrorBoundary } from '@sixfold/app-utils';
import { ThemeProvider, ThemeObject, NotificationBoundary } from '@sixfold/common-ui';
import { transporeonLightClassName } from '@sixfold/common-ui/dist/lib/css';
import transporeonLight from '@sixfold/design-tokens/build/transporeon/json/variables.json';
import iconsUrl from '@sixfold/icons/dist/sprite.svg';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';
import { ErrorPlaceholder } from './components/error_placeholder';
import { getAuthService } from './lib/authorization';
import { getEmbedConfig } from './lib/data';

import '@sixfold/common-ui/dist/lib/styles/global.css';
import '@sixfold/design-tokens/build/transporeon/css/fonts.css';
import '@sixfold/design-tokens/build/transporeon/css/variables.css';
import './app.css';

const themes: Record<string, ThemeObject> = {
  'transporeon-light': {
    className: transporeonLightClassName,
    variables: transporeonLight,
  },
};

const config = getEmbedConfig();
const authService = getAuthService();

const sentryOptions =
  config !== null && config.sentry_reporting_uri
    ? {
        isEnabled: true,
        isHttpClientCaptureEnabled: true,
        dsn: config.sentry_reporting_uri,
        release: config.release,
        environment: config.sentry_environment,
        ignoreErrors: [
          /ResizeObserver loop limit exceeded/,
          /ResizeObserver loop completed with undelivered notifications/,
          // Fix by: https://app.asana.com/0/1202007030618467/1205798219208199
          /Response not successful: Received status code 401/,
          // This is a normal flow for the app, we don't want to report it.
          /Not authorized/,
        ],
        thirdPartyErrorFilter: {
          applicationKey: config.sentry_app_key ?? 'backoffice-web-appkey',
        },
      }
    : { isEnabled: false };

// Define the function externally, so its identity would remain the same across rerenders.
const getIconPath = (name: string) => `${iconsUrl}#${name}`;

ReactDOM.render(
  <ErrorHandlerProvider sentryOptions={sentryOptions}>
    <ErrorBoundary renderError={(error) => <ErrorPlaceholder error={error} />}>
      <ThemeProvider getIconPath={getIconPath} themes={themes} currentTheme="transporeon-light">
        <NotificationBoundary
          className={{
            wrapper: 'notifications',
            container: 'container',
            content: 'content',
            notifications: 'notification-content',
          }}>
          <App authService={authService} />
        </NotificationBoundary>
      </ThemeProvider>
    </ErrorBoundary>
  </ErrorHandlerProvider>,
  document.getElementById('root'),
);
