import { useURLState } from '@sixfold/app-utils';
import { Input, Stack, Box } from '@sixfold/common-ui';
import { Loader } from '@sixfold/loader-container';
import { OptionalBoolean, OptionalString } from '@sixfold/query-string';
import { notNil } from '@sixfold/typed-primitives';
import React from 'react';
import { Query } from 'react-apollo';
import { Button, Dropdown } from 'semantic-ui-react';

import { InfiniteScroll } from '../../components/infinitescroll';
import {
  TelematicsProvidersListQuery,
  TelematicsProvidersListQueryVariables,
  getNodes,
  loadMoreFromConnection,
  PAGINATION_PAGE_SIZE,
  TelematicsProvidersSortInput,
  TelematicsProvidersSortKey,
  SortDirection,
} from '../../lib/graphql';
import { useDebounce } from '../../lib/util/debounce';
import {
  ProvidersList,
  Props as ProvidersListProps,
  useProvidersListSort,
  SortableColumn,
} from '../components/providers_list';
import { telematicsProvidersListQuery } from '../graphql';

class ProvidersListLoadingContainer extends Loader<
  TelematicsProvidersListQuery,
  { telematicsProviders: ProvidersListProps['telematicsProviders']; countMatchingFilter: number },
  object
> {}

class ProvidersListContainerQuery extends Query<TelematicsProvidersListQuery, TelematicsProvidersListQueryVariables> {}

export const TelematicsProvidersContainer: React.FC = () => {
  const [activeFilters, setActiveFilters] = useURLState({
    has_instructions_url: OptionalBoolean,
    is_implemented: OptionalBoolean,
    is_internal_use_only: OptionalBoolean,
    is_external_configuration_needed: OptionalBoolean,
    is_vehicle_management_enabled: OptionalBoolean,
    is_standard_telematics_push_provider: OptionalBoolean,
    is_global_allocation_forbidden: OptionalBoolean,
    is_deprecated: OptionalBoolean,
    search: OptionalString,
  });
  const clearFilters = () =>
    setActiveFilters({
      has_instructions_url: undefined,
      is_implemented: undefined,
      is_internal_use_only: undefined,
      is_external_configuration_needed: undefined,
      is_vehicle_management_enabled: undefined,
      is_standard_telematics_push_provider: undefined,
      is_global_allocation_forbidden: undefined,
      is_deprecated: undefined,
      search: undefined,
    });

  const { sort } = useProvidersListSort();

  const debouncedSearch = useDebounce(activeFilters.search, 500);

  return (
    <ProvidersListContainerQuery
      query={telematicsProvidersListQuery}
      variables={{
        limit: PAGINATION_PAGE_SIZE,
        isIntegrationImplemented: activeFilters.is_implemented,
        hasIntegrationInstructionsURL: activeFilters.has_instructions_url,
        isInternalUseOnly: activeFilters.is_internal_use_only,
        isExternalConfigurationNeeded: activeFilters.is_external_configuration_needed,
        isVehicleManagementEnabled: activeFilters.is_vehicle_management_enabled,
        isStandardTelematicsPushProvider: activeFilters.is_standard_telematics_push_provider,
        isGlobalAllocationForbidden: activeFilters.is_global_allocation_forbidden,
        isDeprecated: activeFilters.is_deprecated,
        textSearch: debouncedSearch ?? undefined,
        sort: sortToGraphql(sort),
      }}>
      {(result) => (
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Box>
              <Input
                autoFocus
                size="medium"
                name="providers-search"
                label="Providers search"
                labelPosition="hidden"
                placeholder="Providers search..."
                value={activeFilters.search ?? ''}
                onChange={({ value }) => {
                  setActiveFilters({ search: value || undefined });
                }}
              />
            </Box>
            <Dropdown
              placeholder="Implementation status"
              selection
              compact
              options={[
                { text: 'Implementation status', value: '' },
                { text: 'Integration implemented', value: 'true' },
                { text: 'Integration not implemented', value: 'false' },
              ]}
              value={activeFilters.is_implemented === undefined ? '' : activeFilters.is_implemented.toString()}
              onChange={(_e, { value }) => {
                setActiveFilters({ is_implemented: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Instructions URL"
              selection
              compact
              options={[
                { text: 'Instructions URL', value: '' },
                { text: 'Has instructions URL', value: 'true' },
                { text: "Doesn't have instructions URL", value: 'false' },
              ]}
              value={
                activeFilters.has_instructions_url === undefined ? '' : activeFilters.has_instructions_url.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ has_instructions_url: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Accessibility"
              selection
              compact
              options={[
                { text: 'Accessibility', value: '' },
                { text: 'Internally accessible only', value: 'true' },
                { text: 'Publicly accessible', value: 'false' },
              ]}
              value={
                activeFilters.is_internal_use_only === undefined ? '' : activeFilters.is_internal_use_only.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ is_internal_use_only: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="External configuration"
              selection
              compact
              options={[
                { text: 'External configuration', value: '' },
                { text: 'External configuration needed', value: 'true' },
                { text: 'External configuration not needed', value: 'false' },
              ]}
              value={
                activeFilters.is_external_configuration_needed === undefined
                  ? ''
                  : activeFilters.is_external_configuration_needed.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ is_external_configuration_needed: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Vehicle management"
              selection
              compact
              options={[
                { text: 'Vehicle management', value: '' },
                { text: 'Vehicle management enabled', value: 'true' },
                { text: 'Vehicle management not enabled', value: 'false' },
              ]}
              value={
                activeFilters.is_vehicle_management_enabled === undefined
                  ? ''
                  : activeFilters.is_vehicle_management_enabled.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ is_vehicle_management_enabled: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Standard telematics push provider"
              selection
              compact
              options={[
                { text: 'Standard telematics push provider', value: '' },
                { text: 'Is standard telematics push provider', value: 'true' },
                { text: 'Is not standard telematics push provider', value: 'false' },
              ]}
              value={
                activeFilters.is_standard_telematics_push_provider === undefined
                  ? ''
                  : activeFilters.is_standard_telematics_push_provider.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ is_standard_telematics_push_provider: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Global allocation"
              selection
              compact
              options={[
                { text: 'Global allocation', value: '' },
                { text: 'Global allocation forbidden', value: 'true' },
                { text: 'Global allocation allowed', value: 'false' },
              ]}
              value={
                activeFilters.is_global_allocation_forbidden === undefined
                  ? ''
                  : activeFilters.is_global_allocation_forbidden.toString()
              }
              onChange={(_e, { value }) => {
                setActiveFilters({ is_global_allocation_forbidden: value === '' ? undefined : value === 'true' });
              }}
            />
            <Dropdown
              placeholder="Deprecated"
              selection
              compact
              options={[
                { text: 'Deprecated', value: '' },
                { text: 'Is deprecated', value: 'true' },
                { text: 'Is not deprecated', value: 'false' },
              ]}
              value={activeFilters.is_deprecated === undefined ? '' : activeFilters.is_deprecated.toString()}
              onChange={(_e, { value }) => {
                setActiveFilters({ is_deprecated: value === '' ? undefined : value === 'true' });
              }}
            />
            {Object.values(activeFilters).some(notNil) && (
              <Button onClick={clearFilters} size="tiny">
                Clear filters
              </Button>
            )}
          </Stack>
          <ProvidersListLoadingContainer
            result={result}
            mapData={({ data }) => ({
              telematicsProviders: getNodes(data.telematicsProvidersV2?.providers).map((provider) => ({
                id: provider.telematicsProviderId,
                name: provider.telematicsProviderName,
                isInternalUseOnly: provider.isInternalUseOnly,
                integrationInstructionsURL: provider.integrationInstructionsURL,
                isIntegrationImplemented: provider.isIntegrationImplemented,
                isExternalConfigurationNeeded: provider.isExternalConfigurationNeeded,
                isVehicleManagementEnabled: provider.isVehicleManagementEnabled,
                isStandardTelematicsPushProvider: provider.isStandardTelematicsPushProvider,
                isGlobalAllocationForbidden: provider.isGlobalAllocationForbidden,
                isDeprecated: provider.isDeprecated,
                numIntegrations: provider.telematicsIntegrations.count,
                numActiveIntegrations: provider.enabledIntegrations.count,
                numTotalTrucks: provider.totalTrucksCount,
                numActiveTrucks: provider.activeTrucksCount,
              })),
              countMatchingFilter: data.telematicsProvidersV2?.count ?? 0,
            })}>
            {({ telematicsProviders, countMatchingFilter }) => (
              <>
                Showing {telematicsProviders.length} out of {countMatchingFilter} providers
                <InfiniteScroll
                  loadMoreEntries={() =>
                    loadMoreFromConnection(telematicsProvidersListQuery, result, ['telematicsProvidersV2', 'providers'])
                  }>
                  <ProvidersList telematicsProviders={telematicsProviders} />
                </InfiniteScroll>
              </>
            )}
          </ProvidersListLoadingContainer>
        </Stack>
      )}
    </ProvidersListContainerQuery>
  );
};

export const sortToGraphql = (sort: ReturnType<typeof useProvidersListSort>['sort']): TelematicsProvidersSortInput => {
  return {
    key: sortToGraphqlMap[sort.key],
    direction: sort.direction === 'ASC' ? SortDirection.ASCENDING : SortDirection.DESCENDING,
  };
};

const sortToGraphqlMap = {
  name: TelematicsProvidersSortKey.PROVIDER_NAME,
  numIntegrations: TelematicsProvidersSortKey.INTEGRATIONS_COUNT,
  numActiveIntegrations: TelematicsProvidersSortKey.ACTIVE_INTEGRATIONS_COUNT,
  numTotalTrucks: TelematicsProvidersSortKey.TRUCKS_COUNT,
  numActiveTrucks: TelematicsProvidersSortKey.ACTIVE_TRUCKS_COUNT,
} satisfies {
  [K in SortableColumn]: TelematicsProvidersSortInput['key'];
};
