import { Form as FormFactory } from '@sixfold/form-component';
import { BackofficeScope } from '@sixfold/session-interface';
import { notNil } from '@sixfold/typed-primitives';
import { History } from 'history';
import React from 'react';
import { MutationFn } from 'react-apollo';
import { Dropdown } from 'semantic-ui-react';

import { Button } from '../../components/button';
import { useHasScopes, useIsAdmin } from '../../lib/authorization';
import {
  ConnectionParameterFieldType,
  CreateTelematicsIntegrationMutation,
  CreateTelematicsIntegrationMutationVariables,
  notEmpty,
  TelematicsProvidersSimpleListQuery,
} from '../../lib/graphql';
import { IntegrationInputField } from '../containers/provider_input_container';
import { ValidateIntegrationButton } from '../containers/telematics_validation_container';
import { getConnectionData, TelematicsProviderIdentifier } from '../lib';

export interface CreateIntegrationViewDataProps {
  data: {
    telematicsProviders: NonNullable<
      NonNullable<TelematicsProvidersSimpleListQuery['telematicsProvidersV2']['providers']['edges']>[number]
    >['node'][];
    company_id: string;
  };
}
export interface CreateIntegrationViewProps {
  createIntegration: MutationFn<CreateTelematicsIntegrationMutation, CreateTelematicsIntegrationMutationVariables>;
  routeProps: {
    push: History['push'];
    goBack: History['goBack'];
  };
}

class Form extends FormFactory<
  { telematicsProvider: string; company_integration_id: string; enabled: boolean } & Record<
    string,
    string | number | boolean
  >
> {}

function isVisibleProvider({ telematicsProviderId }: { telematicsProviderId: string }) {
  return telematicsProviderId !== TelematicsProviderIdentifier.SixfoldCarrierTelematicsApi;
}

type SelectedProvider = NonNullable<CreateIntegrationViewDataProps['data']['telematicsProviders'][number]>;
type FieldValidationError = Record<string, string | undefined>;
export const CreateIntegrationView: React.FunctionComponent<
  CreateIntegrationViewDataProps & CreateIntegrationViewProps
> = ({ routeProps, createIntegration, data }) => {
  const isNotAdmin = !useIsAdmin();
  const hasIntegrationsEditPermission = useHasScopes([BackofficeScope.telematicsWriteIntegrations]);
  const isPrivilegedUser = !isNotAdmin || hasIntegrationsEditPermission;
  const [dynamicValidationErrors, setDynamicValidationErrors] = React.useState<FieldValidationError>({});

  const providerScoped = (provider: string, fieldName: string) => `${provider}_${fieldName}`;
  const implementedProviders = data.telematicsProviders
    .filter(notEmpty)
    .filter(({ isIntegrationImplemented }) => isIntegrationImplemented)
    .filter(isVisibleProvider);

  const enumDefaultValue = (parameter: SelectedProvider['connectionParameters'][number]) => {
    return parameter.enumFieldValues.find((enumFieldValue) => enumFieldValue.is_default)?.value ?? '';
  };

  const someRequiredFieldsMissing = (
    selectedProvider: SelectedProvider,
    form: Record<string, unknown>,
  ): FieldValidationError | undefined => {
    const validationErrors: FieldValidationError = {};
    selectedProvider.connectionParameters
      .filter(({ isOptional }) => !isOptional)
      .forEach((parameter) => {
        const formKey = providerScoped(selectedProvider.telematicsProviderId, parameter.fieldName);
        const formValue = form[formKey];
        const errorMessage = `Enter ${parameter.fieldDisplayName ?? parameter.fieldName}`;

        if (
          formValue === undefined ||
          formValue === null ||
          (typeof formValue === 'string' && formValue.trim() === '')
        ) {
          // workaround: give enum input default value if it already doesnt. It's needed because if user
          // does not select anything then there will be no value, but from UI it looks like there is
          if (parameter.fieldType === ConnectionParameterFieldType.ENUM) {
            form[formKey] = enumDefaultValue(parameter);
            return;
          }
          validationErrors[formKey] = errorMessage;
          return;
        }
      });
    return Object.keys(validationErrors).length !== 0 ? validationErrors : undefined;
  };
  return (
    <Form
      initialValues={{
        telematicsProvider: '',
        company_integration_id: 'main',
        enabled: false,
      }}
      validateFields={{
        company_integration_id: {
          exist: 'Enter a company integration id',
        },
      }}
      onSubmit={async (form) => {
        const selectedProvider = implementedProviders.find(
          (provider) => provider.telematicsProviderId === form.telematicsProvider,
        );
        if (selectedProvider === undefined) {
          // should not happen
          throw new Error(`Couldn't find selected provider '${form.telematicsProvider}' from implemented providers`);
        }
        const requiredFieldsAreMissing = someRequiredFieldsMissing(selectedProvider, form);

        if (requiredFieldsAreMissing !== undefined) {
          setDynamicValidationErrors(requiredFieldsAreMissing);
          return;
        }

        await createIntegration({
          variables: {
            input: {
              company_id: data.company_id,
              telematics_provider_id: selectedProvider.telematicsProviderId,
              company_integration_id: form.company_integration_id,
              enabled: form.enabled,
              connection_data: getConnectionData({
                connectionParameters: selectedProvider.connectionParameters,
                formData: form,
                currentFormFieldKeyFn: (formFieldName) =>
                  providerScoped(selectedProvider.telematicsProviderId, formFieldName),
              }),
            },
          },
        });
        routeProps.push(`/companies/${data.company_id}/integrations`);
      }}>
      {({ form, errors, onChange, onSubmit, isSubmittingForm }) => {
        const getErrorMessage = (
          fieldKey: string,
          staticValidation: FieldValidationError,
          dynamicValidation: FieldValidationError,
        ): string | undefined => {
          if (staticValidation[fieldKey] !== undefined) {
            return staticValidation[fieldKey];
          }
          if (dynamicValidation[fieldKey] !== undefined) {
            return dynamicValidation[fieldKey];
          }
          return undefined;
        };
        const selectedProvider = implementedProviders.find(
          (provider) => provider.telematicsProviderId === form.telematicsProvider,
        );

        return (
          <form className="ui form" onSubmit={onSubmit}>
            <div className="field">
              <label>Telematics provider</label>
              <Dropdown
                selection
                search
                placeholder=" -- select a provider -- "
                name="telematicsProvider"
                options={implementedProviders
                  .sort((providerA, providerB) => {
                    const x = providerA.telematicsProviderName.toLowerCase();
                    const y = providerB.telematicsProviderName.toLowerCase();
                    if (x < y) {
                      return -1;
                    }
                    if (x > y) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((provider) => {
                    return {
                      text: provider.telematicsProviderName,
                      value: provider.telematicsProviderId,
                    };
                  })}
                value={form.telematicsProvider}
                onChange={(_e, data) => onChange({ value: data.value, name: data.name })}
              />
            </div>

            {form.telematicsProvider !== ''
              ? data.telematicsProviders
                  .filter(notEmpty)
                  .filter((provider) => provider.telematicsProviderId === form.telematicsProvider)[0]
                  .connectionParameters.filter(notEmpty)
                  .map((setting) => {
                    const displayName = setting.fieldDisplayName ?? setting.fieldName;
                    const scopedFieldName = providerScoped(form.telematicsProvider, setting.fieldName);
                    const inputValue = () => {
                      if (setting.fieldType === ConnectionParameterFieldType.ENUM) {
                        return notNil(form[scopedFieldName])
                          ? form[scopedFieldName].toString()
                          : enumDefaultValue(setting);
                      }
                      return (form[scopedFieldName] ?? '').toString();
                    };
                    const hasError = getErrorMessage(scopedFieldName, errors, dynamicValidationErrors) !== undefined;
                    return (
                      <div key={scopedFieldName} className="field">
                        <label>
                          {hasError
                            ? getErrorMessage(scopedFieldName, errors, dynamicValidationErrors)
                            : `${displayName}${setting.isOptional ? ' (optional)' : ''}${
                                setting.isInternal ? ' (internal)' : ''
                              }`}
                        </label>

                        <IntegrationInputField
                          fieldType={setting.fieldType}
                          settingsValue={inputValue()}
                          fieldName={scopedFieldName}
                          disabled={!isPrivilegedUser}
                          defaultChecked={form[setting.fieldName] === true}
                          enumFieldValues={setting.enumFieldValues ?? []}
                          onChange={onChange}
                          hasError={hasError}
                        />
                      </div>
                    );
                  })
              : null}
            {selectedProvider !== undefined && (
              <React.Fragment>
                <div className="field">
                  <label>{errors.company_integration_id ?? 'Company integration id'}</label>
                  <input
                    type="text"
                    name="company_integration_id"
                    value={form.company_integration_id}
                    disabled={!isPrivilegedUser}
                    onChange={onChange}
                  />
                </div>
                <div className="field">
                  <label>Enabled</label>
                  <input type="checkbox" name="enabled" disabled={!isPrivilegedUser} onChange={onChange} />
                </div>
                <div className="integration__configuration-footer">
                  <div>
                    <Button type="submit" primary disabled={!isPrivilegedUser || isSubmittingForm}>
                      Add
                    </Button>
                    <Button type="button" onClick={() => routeProps.goBack()}>
                      Cancel
                    </Button>
                  </div>

                  <ValidateIntegrationButton
                    integration={{
                      telematics_provider_id: selectedProvider.telematicsProviderId,
                      connection_data: getConnectionData({
                        connectionParameters: selectedProvider.connectionParameters,
                        formData: form,
                        currentFormFieldKeyFn: (formFieldName) =>
                          providerScoped(selectedProvider.telematicsProviderId, formFieldName),
                      }),
                    }}
                  />
                </div>
              </React.Fragment>
            )}
          </form>
        );
      }}
    </Form>
  );
};
